import React, { useEffect, useState } from 'react';

import { ReactComponent as AlertIcon } from '../../../assets/icons/alert-icon.svg';
import { ReactComponent as Export } from '../../../assets/icons/export.svg';
import { ReactComponent as EyeIcon } from '../../../assets/icons/eye-2-icon.svg';
import { ReactComponent as FacilityIcon } from '../../../assets/icons/facility-color-icon.svg';
import { ReactComponent as Location } from '../../../assets/icons/location.svg';
import DefaultApiParameters from '../../../enum/DefaultApiParameters';
import { ChargingStationInterFace } from '../../../interface/charging-station.interface';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { fetchAllRejectedStation } from '../../../redux/slices/RejectedStationManagementSlice';
import MyButton from '../../../shared-components/button/Button';
import CustomTable from '../../../shared-components/custom-table/CustomTable';
import ModalBox from '../../../shared-components/modal-box/ModalBox';

import FilterChargingStation from './filter-charging-station';

/**
 * Rejected charging station list function component
 */
function RejectedList({
  viewFunction,
}: {
  viewFunction: (value: ChargingStationInterFace) => void;
}) {
  const dispatch = useAppDispatch();
  const { stations, limit, page, totalPages, totalResults, loading } = useAppSelector(
    (state) => state.rejectedStation,
  );
  const [modalView, setModalView] = useState({
    status: false,
    title: '',
    header: true,
    content: <div />,
    rightCorner: '',
  });
  const column = [
    {
      label: 'Station Name',
      accessorKey: 'name' as keyof ChargingStationInterFace,
    },
    {
      label: 'Land Document',
      accessorKey: 'output' as keyof ChargingStationInterFace,
    },
    {
      label: 'ER Reports',
      accessorKey: 'ac_dc' as keyof ChargingStationInterFace,
    },
    {
      label: 'Electricity Type',
      accessorKey: 'phase' as keyof ChargingStationInterFace,
    },
    {
      label: 'Operating Hrs',
      accessorKey: 'charging_type' as keyof ChargingStationInterFace,
    },
    {
      label: 'Location',
      accessorKey: 'station_location' as keyof ChargingStationInterFace,
      /**
       * custom data function
       * @param currentRow
       */
      customData: (currentRow: ChargingStationInterFace) => (
        <div className="tag-success-label">
          <Location /> <span>{currentRow.address}</span>
        </div>
      ),
    },
    {
      label: 'Reject Reason',
      accessorKey: 'status' as keyof ChargingStationInterFace,
      /**
       * custom data function
       * @param currentRow
       */
      customData: (currentRow: ChargingStationInterFace) => (
        <div>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
            <AlertIcon />
            <span style={{ fontWeight: '700' }}>{currentRow.submission.reason}</span>
          </div>
        </div>
      ),
    },
  ];

  /**
   * Handle the fetch the data in api function
   * @param data
   */
  const fetchData = (data: { limit: number; page: number; submissionStatus: string }) => {
    if (!loading) {
      dispatch(fetchAllRejectedStation(data));
    }
  };

  /**
   * Close modal function
   */
  const closeModal = () => {
    setModalView({
      status: false,
      title: '',
      header: false,
      content: <div />,
      rightCorner: '',
    });
  };

  /**
   * View the charging station details
   */
  const viewChargingStation = (stationId: String) => {
    const stationDetails = stations.find(
      (value: ChargingStationInterFace) => value.id === stationId,
    );
    if (stationDetails && Object.keys(stationDetails).length > 0) {
      viewFunction(stationDetails);
    }
  };

  /**
   * Open the filter modal function
   */
  const openFilter = () => {
    setModalView({
      status: true,
      title: '',
      header: false,
      content: <FilterChargingStation closeModal={closeModal} />,
      rightCorner: 'filter-modal',
    });
  };

  /**
   * Clear the filter data function
   */
  const clearFilter = () => {
    setModalView({
      status: true,
      title: '',
      header: false,
      content: <FilterChargingStation closeModal={closeModal} />,
      rightCorner: 'filter-modal',
    });
  };

  /**
   * Table pagination function
   * @param value
   */
  const pageNateLimitChange = (value: { limit: number; page: number }) => {
    fetchData({ ...value, submissionStatus: 'rejected' });
  };

  useEffect(() => {
    if (stations.length === 0) {
      fetchData({
        page: DefaultApiParameters.page,
        limit: DefaultApiParameters.limit,
        submissionStatus: 'rejected',
      });
    }
  }, []);

  return (
    <>
      <ModalBox
        open={modalView.status}
        title={modalView.title}
        content={modalView.content}
        closeModal={closeModal}
        header={modalView.header}
        rightCorner={modalView.rightCorner}
      />
      <CustomTable
        header={column}
        data={stations}
        serialNo
        actionButton
        limit={limit}
        page={page}
        totalPages={totalPages}
        totalResults={totalResults}
        filterFunction={openFilter}
        filterClearFunction={clearFilter}
        pageLimitChange={pageNateLimitChange}
        sizeChangeButtonRequired
        height={400}
        actionButtonList={[
          {
            icon: <EyeIcon />,
            function: viewChargingStation,
            color: 'view-color',
          },
        ]}
        footerLeft={
          <div className="table-bottom-button-list">
            <div className="table-button-item">
              <MyButton
                label="Export Data"
                buttonType="back"
                onClickFunc={() => {}}
                svgIcon={<Export />}
              />
            </div>
            <div className="table-button-item">
              <div className="user-count">
                <div className="icon">
                  <FacilityIcon />
                </div>
                <div className="value">{totalResults} Facilities</div>
              </div>
            </div>
          </div>
        }
      />
    </>
  );
}

export default RejectedList;
