import React, { useRef } from 'react';
import ReactDOM from 'react-dom';

import { ReactComponent as CloseCircle } from '../../assets/icons/close-circle.svg';

/**
 * Modal Box function component
 */
function ModalBox({
  title,
  content,
  closeModal,
  open,
  header,
  rightCorner,
}: {
  title: String;
  content: JSX.Element;
  closeModal: () => void;
  open: boolean;
  header: boolean;
  rightCorner: string;
}) {
  const reference = useRef<HTMLDivElement>(null);
  /**
   * Click the outside page function
   */
  const handleClickOutside = (event: React.MouseEvent) => {
    if (reference.current === event.target) {
      closeModal();
    }
  };
  return open
    ? ReactDOM.createPortal(
        <div
          className={`modal-box-wrap open ${rightCorner}`}
          onClick={handleClickOutside}
          role="presentation"
          ref={reference}>
          <div className="modal-box-container">
            {header && (
              <div className="modal-box-header">
                <div className="modal-box-title">
                  <span>{title}</span>
                </div>
                <div className="modal-box-icon" onClick={closeModal} role="presentation">
                  <CloseCircle />
                </div>
              </div>
            )}
            <div className="modal-box-body">
              {!header && (
                <div className="modal-box-icon" onClick={closeModal} role="presentation">
                  <CloseCircle />
                </div>
              )}
              {content}
            </div>
          </div>
        </div>,
        document.body,
      )
    : null;
}

export default ModalBox;
