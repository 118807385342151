/**
 * Change the date format YYYY-MM-DD function
 * @param value 
 * @returns 
 */
export const changeDateFormat = (value: string) => {
  const date = new Date(value);
  const dateMDY = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
  return dateMDY;
};

/**
 * Change the time format HH:MM function
 * @param value 
 * @returns 
 */
export const changeTimeFormat = (value: string) => {
  const date = new Date(value);
  const dateMDY = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
  return dateMDY;
};