/* eslint-disable no-param-reassign */
import { ActionReducerMapBuilder, createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import DefaultApiParameters from '../../enum/DefaultApiParameters';
import { APIRoleResponseInterface, RoleInterFace } from '../../interface/role-management.interface';
import { changeDateFormat } from '../../shared-functions/DateTime';
import roleApi from '../../utils/api/roles';

// Define the type of data expected
interface FetchParameters {
  search?: string;
  page?: number;
  limit?: number;
}

interface RoleInitialProperties {
  roles: RoleInterFace[];
  rolesList: RoleInterFace[];
  limit: number,
  page: number,
  totalPages: number,
  totalResults: number,
  loading: boolean;
}
// a thunk to fetch users
export const fetchAllRoles = createAsyncThunk<APIRoleResponseInterface, FetchParameters>(
  'role-management/fetchAllRoles',
  async (data: FetchParameters) => {
    const response = await roleApi.fetchRoles(data);
    return {
      ...response.data,
      results: response.data.results.map((roleData: RoleInterFace) => ({
        ...roleData,
        date: changeDateFormat(roleData.createdAt || ''),
        id: roleData._id,
      })),
    };
  },
);

export const fetchAllListRoles = createAsyncThunk<RoleInterFace[], FetchParameters>(
  'role-management/fetchAllListRoles',
  async (data: FetchParameters) => {
    const response = await roleApi.fetchAllRoles(data);
    return response.data.map((roleData: RoleInterFace) => ({
      ...roleData,
      date: changeDateFormat(roleData.createdAt || ''),
      id: roleData._id,
    }));
  },
);

// Initial state
const initialState: RoleInitialProperties = {
  roles: [],
  rolesList: [],
  limit: DefaultApiParameters.limit,
  page: DefaultApiParameters.page,
  totalPages: 0,
  totalResults: 0,
  loading: false,
};

// Create the slice
export const roleSlice = createSlice({
  name: 'roles-management',
  initialState,
  reducers: {},
  /**
   * Use the extra reducers function
   * @param builder
   */
  extraReducers: (builder: ActionReducerMapBuilder<RoleInitialProperties>) => {
    builder
      .addCase(fetchAllRoles.pending, (state: RoleInitialProperties) => {
        state.loading = true;
      })
      .addCase(
        fetchAllRoles.fulfilled,
        (state: RoleInitialProperties, action: PayloadAction<APIRoleResponseInterface>) => {
          state.loading = false;
          state.roles = [...action.payload.results];
          state.limit = action.payload.limit;
          state.page = action.payload.page;
          state.totalPages = action.payload.totalPages;
          state.totalResults = action.payload.totalResults;
        },
      )
      .addCase(fetchAllRoles.rejected, (state: RoleInitialProperties) => {
        state.loading = false;
        // Handle error if needed
      });
    builder
      .addCase(fetchAllListRoles.pending, (state: RoleInitialProperties) => {
        state.loading = true;
      })
      .addCase(
        fetchAllListRoles.fulfilled,
        (state: RoleInitialProperties, action: PayloadAction<RoleInterFace[]>) => {
          state.loading = false;
          state.rolesList = [...action.payload];
        },
      )
      .addCase(fetchAllListRoles.rejected, (state: RoleInitialProperties) => {
        state.loading = false;
        // Handle error if needed
      });
  },
});
// Export the reducer
export default roleSlice.reducer;
