import React, { useEffect, useState } from 'react';

import { ReactComponent as CheckCircle } from '../../assets/icons/check-circle.svg';
import { ReactComponent as Delete } from '../../assets/icons/delete.svg';
import { ReactComponent as DotCircle } from '../../assets/icons/dot-circle.svg';
import { ReactComponent as EditPencilCard } from '../../assets/icons/edit-pencil.svg';
import { ReactComponent as Export } from '../../assets/icons/export.svg';
import { ReactComponent as EyeIcon } from '../../assets/icons/eye-2-icon.svg';
import { ReactComponent as Plus } from '../../assets/icons/plus.svg';
import { AccountsInterFace } from '../../interface/account-management.interface';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { fetchAllAccounts } from '../../redux/slices/AccountManagementSlice';
import MyButton from '../../shared-components/button/Button';
import CustomTable from '../../shared-components/custom-table/CustomTable';
import ConfirmBox from '../../shared-components/modal-box/ConfirmBox';
import ModalBox from '../../shared-components/modal-box/ModalBox';
import accountApi from '../../utils/api/account';

import AddAccount from './components/add-account';
import DeleteAccount from './components/delete-account';
import FilterAccount from './components/filter-account';
import ViewAccount from './components/view-account';

interface ModalInterface {
  title: string;
  header: boolean;
  content: JSX.Element;
  rightCorner: string;
}

/**
 * Account Management function Component
 */
function AccountManagement() {
  const dispatch = useAppDispatch();
  const { accounts, limit, page, totalPages, totalResults, loading } = useAppSelector(
    (state) => state.accounts,
  );
  const column = [
    {
      label: 'Date',
      accessorKey: 'date' as keyof AccountsInterFace,
    },
    {
      label: 'Account Id',
      accessorKey: 'accountCustomId' as keyof AccountsInterFace,
    },
    {
      label: 'Verification Status',
      accessorKey: 'accountVerified' as keyof AccountsInterFace,
      /**
       * Data custom function
       * @param currentRow
       */
      customData: (currentRow: AccountsInterFace) =>
        currentRow.accountVerified ? (
          <div className="tag-success-label">
            <CheckCircle /> <span>Verified</span>
          </div>
        ) : (
          <div className="tag-default-label">
            <DotCircle /> <span>Un Verified</span>
          </div>
        ),
    },
    {
      label: 'Account Type',
      accessorKey: 'account_type' as keyof AccountsInterFace,
    },
  ];

  const [openStatus, setOpenStatus] = useState<boolean>(false);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [account, setAccount] = useState<AccountsInterFace>({
    _id: '',
    id: '',
    name: '',
    email: '',
    date: '',
    phone: '',
    tenantId: '',
    accountVerified: false,
    documents: [],
    currency: '',
  });
  const [modalView, setModalView] = useState<ModalInterface>({
    title: '',
    header: false,
    content: <div />,
    rightCorner: '',
  });

  /**
   * Fetch the account details in redus
   */
  const fetchData = (data: { page: number, limit: number }) => {
    if (!loading) {
      dispatch(fetchAllAccounts(data));
    }
  };
  /**
   * Modal box close function
   */
  const closeModal = () => {
    setOpenStatus(false);
    setOpenConfirm(false);
  };

  /**
   * Add account function
   */
  const addAccount = () => {
    setOpenStatus(true);
    setModalView({
      title: 'Add Account',
      header: true,
      content: <AddAccount closeModal={closeModal} />,
      rightCorner: '',
    });
  };

  /**
   * Edit account function
   */
  const editAccount = (currentData: String) => {
    const accountDetails = accounts.find((value: AccountsInterFace) => value.id === currentData);
    if (accountDetails && Object.keys(accountDetails).length > 0) {
      setOpenStatus(true);
      setModalView({
        title: 'Edit Account',
        header: true,
        content: <AddAccount closeModal={closeModal} currentData={accountDetails} />,
        rightCorner: '',
      });
    }
  };

  /**
   * Table pagination function
   * @param value
   */
  const pageNateLimitChange = (value: any) => {
    fetchData(value);
  };

  /**
   * Confirm modal box submit function
   */
  const conformSubmit = (data: AccountsInterFace) => {
    setOpenConfirm(true);
    setAccount(data);
  };

  /**
   * Account delete submit function
   */
  const deleteFunction = (currentData: String) => {
    const accountDetails = accounts.find((value: AccountsInterFace) => value.id === currentData);
    if (accountDetails && Object.keys(accountDetails).length > 0) {
      accountApi
        .fetchOneAccounts(accountDetails.tenantId)
        .then((response) => {
          setOpenStatus(true);
          setModalView({
            title: 'Add Account',
            header: false,
            content: (
              <DeleteAccount
                accountDetails={{ ...accountDetails, ...response.data }}
                conformSubmit={conformSubmit}
                closeModal={closeModal}
              />
            ),
            rightCorner: '',
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  /**
   * Account details view function
   */
  const viewFunction = (currentData: String) => {
    const accountDetails = accounts.find((value: AccountsInterFace) => value.id === currentData);
    if (accountDetails && Object.keys(accountDetails).length > 0) {
      setOpenStatus(true);
      setModalView({
        title: '',
        header: false,
        content: (
          <ViewAccount
            accountDetails={accountDetails}
            closeModal={closeModal}
            conformSubmit={() => {}}
          />
        ),
        rightCorner: 'right-top',
      });
    }
  };

  /**
   * Delete confirm function
   */
  const deleteConfirmFunction = () => {
    accountApi.deleteAccount(account.tenantId).then(() => {
      closeModal();
    });
  };

  /**
   * Account filter view function
   */
  const accountFilterFunction = () => {
    setOpenStatus(true);
    setModalView({
      title: 'Add Account',
      header: false,
      content: <FilterAccount closeModal={closeModal} />,
      rightCorner: 'filter-modal',
    });
  };

  useEffect(() => {
    if (accounts.length === 0) {
      fetchData({
        limit,
        page,
      });
    }
  }, []);
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <ModalBox
        open={openStatus}
        title={modalView.title}
        content={modalView.content}
        closeModal={() => setOpenStatus(false)}
        header={modalView.header}
        rightCorner={modalView.rightCorner}
      />
      <ConfirmBox
        open={openConfirm}
        title="Are you sure want to verify this account?"
        subTitle="This will allow facility owner account to add facility& devices"
        conformSubmit={deleteConfirmFunction}
        closeModal={() => {
          setOpenConfirm(false);
          setOpenStatus(false);
          setAccount({
            _id: '',
            id: '',
            name: '',
            email: '',
            date: '',
            phone: '',
            tenantId: '',
            accountVerified: false,
            currency: '',
          });
        }}
      />
      <div className="container">
        <div className="header-title">
          <h2>Account Management</h2>
        </div>
        <div className="body-container">
          <div className="table-content">
            <CustomTable
              header={column}
              data={accounts}
              limit={limit}
              page={page}
              totalPages={totalPages}
              totalResults={totalResults}
              loading={loading}
              serialNo
              pageLimitChange={pageNateLimitChange}
              actionButton
              height={354}
              filterFunction={accountFilterFunction}
              filterClearFunction={() => {}}
              sizeChangeButtonRequired
              actionDropdown
              actionDropDownList={[
                {
                  content: (
                    <div className="flex-row">
                      <div className="text-primary">
                        <EditPencilCard />
                      </div>{' '}
                      Edit
                    </div>
                  ),
                  function: editAccount,
                },
                {
                  content: (
                    <div className="flex-row">
                      <div className="text-primary">
                        <EyeIcon />
                      </div>{' '}
                      View
                    </div>
                  ),
                  function: viewFunction,
                },
                {
                  content: (
                    <div className="flex-row">
                      <div className="text-danger">
                        <Delete />
                      </div>{' '}
                      Block
                    </div>
                  ),
                  function: deleteFunction,
                },
              ]}
              footerLeft={
                <div className="table-bottom-button-list">
                  <div className="table-button-item">
                    <MyButton
                      label="Add Account"
                      buttonType="submit"
                      onClickFunc={addAccount}
                      svgIcon={<Plus />}
                    />
                  </div>
                  <div className="table-button-item">
                    <MyButton
                      label="Export Data"
                      buttonType="back"
                      onClickFunc={() => {}}
                      svgIcon={<Export />}
                    />
                  </div>
                </div>
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccountManagement;
