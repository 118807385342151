/* eslint-disable no-param-reassign */
import {
  ActionReducerMapBuilder,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import DefaultApiParameters from '../../enum/DefaultApiParameters';
import { AccountsInterFace, APIAccountResponseInterface } from '../../interface/account-management.interface';
import { changeDateFormat } from '../../shared-functions/DateTime';
import accountApi from '../../utils/api/account';

// Define the type of data expected
interface FetchParameters {
  search?: string;
  page?: number;
  limit?: number;
}

interface AccountInitialProperties {
  accounts: AccountsInterFace[];
  limit: number;
  page: number;
  totalPages: number;
  totalResults: number;
  loading: boolean;
}

// a thunk to fetch accounts
export const fetchAllAccounts = createAsyncThunk<APIAccountResponseInterface, FetchParameters>(
  'account-management/fetchAllAccounts',
  async (data: FetchParameters) => {
    const response = await accountApi.fetchAccounts(data);
    return {
      ...response.data,
      results: response.data.results.map((roleData: any) => ({
        ...roleData,
        date: changeDateFormat(roleData.createdAt),
        id: roleData._id,
      })),
    };
  },
);

// Initial state
const initialState: AccountInitialProperties = {
  accounts: [],
  limit: DefaultApiParameters.limit,
  page: DefaultApiParameters.page,
  totalPages: 0,
  totalResults: 0,
  loading: false,
};

// Create the slice
export const accountSlice = createSlice({
  name: 'account-management',
  initialState,
  reducers: {},
  /**
   * Use the extra reducers function
   * @param builder
   */
  extraReducers: (builder: ActionReducerMapBuilder<AccountInitialProperties>) => {
    builder
      .addCase(fetchAllAccounts.pending, (state: AccountInitialProperties) => {
        state.loading = true;
      })
      .addCase(
        fetchAllAccounts.fulfilled,
        (state: AccountInitialProperties, action: PayloadAction<APIAccountResponseInterface>) => {
          state.loading = false;
          state.accounts = [...action.payload.results];
          state.limit = action.payload.limit;
          state.page = action.payload.page;
          state.totalPages = action.payload.totalPages;
          state.totalResults = action.payload.totalResults;
        },
      )
      .addCase(fetchAllAccounts.rejected, (state: AccountInitialProperties) => {
        state.loading = false;
        // Handle error if needed
      });
  },
});

// Export the actions
// export const {} = counterSlice.actions;

// Export the reducer
export default accountSlice.reducer;
