import { useState, useEffect, useRef } from "react";

interface MenuList {
  function: (value: String) => void;
  content: JSX.Element;
}

/**
 * Drop Down function component
 */
function DropDown({ list, icon, id = ''}: { list: MenuList[]; icon: JSX.Element; id?: String }) {
  const reference = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  /**
   * Handle the close th drop down function
   * @param event 
   */
  const handleClickOutside = (event: MouseEvent) => {
    if (reference.current && !reference.current.contains(event.target as Node)) {
      setOpen(false);
    }
  };
  useEffect((): void => {
    document.addEventListener('mousedown', handleClickOutside);
  }, []);

  return (
  <div className="drop-down">
    <div className="drop-down-title" onClick={() => setOpen(!open)} role="presentation">
      {icon}
    </div>
    <div className={`drop-down-content ${open && 'open'}`} ref={reference}>
      {list.map((value) => (
        <div className="drop-down-content-list-item" onClick={() => value.function(id)} role="presentation">{value.content}</div>
      ))}
    </div>
  </div>
  );
}

DropDown.defaultProps = {
  id: null,
};

export default DropDown;
