import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// Sidebar icons
import { ReactComponent as AccountActiveIcon } from '../../assets/icons/sidebar/sidebar-account-active.svg';
import { ReactComponent as AccountIcon } from '../../assets/icons/sidebar/sidebar-account.svg';
import { ReactComponent as ConsumerActiveIcon } from '../../assets/icons/sidebar/sidebar-end-consumer-active.svg';
import { ReactComponent as ConsumerIcon } from '../../assets/icons/sidebar/sidebar-end-consumer.svg';
import { ReactComponent as HomeActiveIcon } from '../../assets/icons/sidebar/sidebar-home-active.svg';
import { ReactComponent as HomeIcon } from '../../assets/icons/sidebar/sidebar-home.svg';
import { ReactComponent as PaymentActiveIcon } from '../../assets/icons/sidebar/sidebar-payment-active.svg';
import { ReactComponent as PaymentIcon } from '../../assets/icons/sidebar/sidebar-payment.svg';
import { ReactComponent as ReportActiveIcon } from '../../assets/icons/sidebar/sidebar-report-active.svg';
import { ReactComponent as ReportIcon } from '../../assets/icons/sidebar/sidebar-report.svg';
import { ReactComponent as StationActiveIcon } from '../../assets/icons/sidebar/sidebar-station-active.svg';
import { ReactComponent as StationIcon } from '../../assets/icons/sidebar/sidebar-station.svg';
import { ReactComponent as UserActiveIcon } from '../../assets/icons/sidebar/sidebar-user-active.svg';
import { ReactComponent as UserIcon } from '../../assets/icons/sidebar/sidebar-user.svg';
import { ReactComponent as ToggleClose } from '../../assets/icons/toggle-close.svg';
import { ReactComponent as ToggleOpen } from '../../assets/icons/toggle-open.svg';

/**
 * Side bar function component
 */
function Sidebar() {
  const [expandSidebar, setExpandSidebar] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [sidebarMenu, setSideBarMenu] = useState([
    {
      id: 1,
      label: 'Dashboard',
      url: '/ev-admin/dashboard',
      svgIcon: <HomeIcon />,
      activeSvgIcon: <HomeActiveIcon />,
    },
    {
      id: 2,
      label: 'Accounts Management',
      url: '/ev-admin/account',
      svgIcon: <AccountIcon />,
      activeSvgIcon: <AccountActiveIcon />,
    },
    {
      id: 3,
      label: 'Charging Station Management',
      url: '/ev-admin/charging-station',
      svgIcon: <StationIcon />,
      activeSvgIcon: <StationActiveIcon />,
    },
    {
      id: 4,
      label: 'Reports',
      url: '/ev-admin/reports',
      svgIcon: <ReportIcon />,
      activeSvgIcon: <ReportActiveIcon />,
    },
    {
      id: 5,
      label: 'User & Role Management',
      url: '/ev-admin/user',
      svgIcon: <UserIcon />,
      activeSvgIcon: <UserActiveIcon />,
    },
    {
      id: 6,
      label: 'End Consumer ',
      url: '/ev-admin/end-consumer',
      svgIcon: <ConsumerIcon />,
      activeSvgIcon: <ConsumerActiveIcon />,
    },
    {
      id: 6,
      label: 'Payments',
      url: '/ev-admin/payments',
      svgIcon: <PaymentIcon />,
      activeSvgIcon: <PaymentActiveIcon />,
    },
  ]);

  /**
   * Handle the click menu function
   * @param url
   */
  const handleMenuClick = (url: string) => {
    setSideBarMenu((previous) =>
      previous.map((menu) => ({
        ...menu,
        isActive: menu.url === url,
      })),
    );
    navigate(url);
  };

  return (
    <div className="sidebar-wrap">
      <div className={`sidebar-container ${expandSidebar ? 'expand' : 'close'}`}>
        <div
          className="toggle-button"
          role="presentation"
          onClick={() => setExpandSidebar(!expandSidebar)}>
          <span
            role="presentation"
            onClick={() => setExpandSidebar(true)}
            className={`toggle-icon ${expandSidebar ? 'disable' : 'active'}`}>
            <ToggleOpen />
          </span>
          <span
            role="presentation"
            onClick={() => setExpandSidebar(false)}
            className={`toggle-icon ${expandSidebar ? 'active' : 'disable'}`}>
            <ToggleClose />
          </span>
        </div>
        <div className="sidebar-menus">
          {sidebarMenu?.map((menu) => (
            <div
              className={`sidebar-menu ${expandSidebar ? 'expand' : 'close'} ${location.pathname === menu.url ? 'active' : 'in-active'}`}
              key={menu?.id}
              role="presentation"
              onClick={() => handleMenuClick(menu.url)}>
              <div className={`menu-wrap ${location.pathname === menu.url ? 'active' : 'in-active'}`}>
                <div className={`menu-icon-container ${location.pathname === menu.url ? 'active' : 'in-active'}`}>
                  <span>{location.pathname === menu.url ? menu.activeSvgIcon : menu.svgIcon}</span>
                </div>
                <span
                  className={`menu-label ${expandSidebar ? 'expand' : 'close'} ${location.pathname === menu.url ? 'active' : 'in-active'}`}>
                  {menu.label}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
