import React, { useEffect, useState } from 'react';

import { RoleInterFace } from '../../../interface/role-management.interface';
import {
  UserAddInterFace,
  UserInterFace,
  UserParameters,
} from '../../../interface/users-management.interface';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { fetchAllListRoles } from '../../../redux/slices/RolesManagementSlice';
import MyButton from '../../../shared-components/button/Button';
import CustomInput from '../../../shared-components/custom-input/CustomInput';
import SelectFiled from '../../../shared-components/select-field/SelectField';
import userApi from '../../../utils/api/users';

/**
 * Add User page function component
 */
function AddUser({
  closeModal,
  currentDate = {
    id: '',
    _id: '',
    name: '',
    date: '',
    accountId: '',
    countryCode: '',
    email: '',
    phoneNumber: '',
    role: '',
    status: false,
    createdAt: ''
  },
  completedTask,
}: {
  closeModal: () => void;
  currentDate?: UserInterFace;
  completedTask: (message: string, type: string) => void;
}) {
  const dispatch = useAppDispatch();
  const { rolesList } = useAppSelector((state) => state.roles);

  const [disabled, setDisabled] = useState(false);
  const [userDetails, setUserDetails] = useState<UserAddInterFace>({
    role: currentDate.role,
    name: currentDate.name,
    email: currentDate.email,
    password: '',
    rePassword: '',
  });

  /**
   * Fetch Users list function
   */
  const fetchData = () => {
    dispatch(fetchAllListRoles({}));
  };

  /**
   * Handle the input change function
   * @param name
   * @param value
   */
  const handleInputChange = (name: string, value: string | number | (string | number)[]) => {
    setUserDetails((previous) => ({
      ...previous,
      [name]: value,
    }));
  };

  /**
   * Handle the submit function
   */
  const handleSubmit = () => {
    let errorMessage = false;
    if (!currentDate.id) {
      if (
        userDetails.role === '' ||
        userDetails.name === '' ||
        userDetails.email === '' ||
        userDetails.password === '' ||
        userDetails.rePassword !== userDetails.password
      ) {
        errorMessage = true;
      }
      if (!errorMessage) {
        setDisabled(true);
        const request: UserParameters = {
          name: userDetails.name,
          role: userDetails.role,
          email: userDetails.email,
          password: userDetails.password,
        };
        userApi
          .createUser(request)
          .then(() => {
            setDisabled(false);
            completedTask('User Updated Successfully', 'user');
          })
          .catch((error) => {
            console.error('Failed to create role:', error);
            setDisabled(false);
          });
      }
    } else {
      if (
        userDetails.role === '' ||
        userDetails.name === '' ||
        userDetails.email === '' ||
        !currentDate.id
      ) {
        errorMessage = true;
      }
      if (!errorMessage) {
        setDisabled(true);
        const request: UserParameters = {
          name: userDetails.name,
          role: userDetails.role,
          email: userDetails.email,
        };
        userApi
          .editUser(currentDate.id, request)
          .then(() => {
            setDisabled(false);
            completedTask('User Added Successfully', 'user');
          })
          .catch((error) => {
            console.error('Failed to create role:', error);
            setDisabled(false);
          });
      }
    }
  };

  useEffect(() => {
    if (rolesList.length === 0) {
      fetchData();
    }
  }, []);

  return (
    <div className="add-account-form">
      <div className="form-body">
        <div className="form-field">
          <SelectFiled
            name="role"
            inputLabel="Role"
            selectedValue={userDetails.role}
            placeholder="Select Role"
            onChangeValue={handleInputChange}
            options={rolesList.map((role: RoleInterFace) => ({
              label: role.name,
              value: role.id,
            }))}
          />
        </div>
        <div className="form-field">
          <CustomInput
            name="name"
            inputLabel="Name"
            type="text"
            placeholder="Enter the name"
            value={userDetails.name}
            onChangeValue={handleInputChange}
          />
        </div>
        <div className="form-field">
          <CustomInput
            name="email"
            inputLabel="Email"
            type="email"
            placeholder="Enter the email"
            value={userDetails.email}
            onChangeValue={handleInputChange}
          />
        </div>
        {!currentDate.id && (
          <>
            <div className="form-field">
              <CustomInput
                name="password"
                inputLabel="Password"
                type="password"
                placeholder="Enter Password"
                value={userDetails.password}
                onChangeValue={handleInputChange}
              />
            </div>
            <div className="form-field">
              <CustomInput
                name="rePassword"
                inputLabel="Re-Enter Password"
                type="password"
                placeholder="Enter Password"
                value={userDetails.rePassword}
                onChangeValue={handleInputChange}
              />
            </div>
          </>
        )}
        <div className="form-field table-bottom-button-list form-bottom-button end">
          <div className="table-button-item">
            <MyButton label="Cancel" buttonType="back" onClickFunc={closeModal} />
          </div>
          <div className="table-button-item">
            <MyButton
              label="Submit"
              buttonType="submit"
              disabled={disabled}
              onClickFunc={handleSubmit}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

AddUser.defaultProps = {
  currentDate: {},
};

export default AddUser;
