import React, { useEffect, useState } from 'react';

import { ReactComponent as ActivityIcon } from '../../../assets/icons/activety-icon.svg';
import { ReactComponent as CheckCircle } from '../../../assets/icons/check-circle.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete-icon.svg';
import { ReactComponent as DotCircle } from '../../../assets/icons/dot-circle.svg';
import { ReactComponent as Export } from '../../../assets/icons/export.svg';
import { ReactComponent as GridList } from '../../../assets/icons/grid-list.svg';
import { ReactComponent as TableList } from '../../../assets/icons/table-list.svg';
import DefaultApiParameters from '../../../enum/DefaultApiParameters';
import { ApiRequest } from '../../../interface/common.interface';
import { UserInterFace } from '../../../interface/users-management.interface';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { fetchAllUsers } from '../../../redux/slices/UsersManagementSlice';
import MyButton from '../../../shared-components/button/Button';
import CardBox from '../../../shared-components/card-box/CardBox';
import CustomTable from '../../../shared-components/custom-table/CustomTable';
import ConfirmBox from '../../../shared-components/modal-box/ConfirmBox';
import ModalBox from '../../../shared-components/modal-box/ModalBox';
import Pagination from '../../../shared-components/pagination/Pagination';
import TopHeaderButtons from '../../../shared-components/top-header-buttons/TopHeaderButtons';
import userApi from '../../../utils/api/users';

import ActiveLogCard from './active-log-card';
import ConsumerCardBody from './consumer-card-body';
import FilterUser from './filter-user';

/**
 * User Management page function component
 */
function UsersList() {
  const dispatch = useAppDispatch();
  const { users, limit, page, totalPages, totalResults, loading } = useAppSelector(
    (state) => state.users,
  );

  const [activeLogModal, setActiveLogModal] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openTab, setOpenTab] = useState(0);
  const [accountId, setAccountId] = useState<String>('');
  const [modalView, setModalView] = useState({
    status: false,
    title: 'Add Account',
    header: true,
    content: <div />,
    rightCorner: '',
  });

  /**
   * Fetch Users list function
   */
  const fetchData = (data: ApiRequest) => {
    if (!loading) {
      dispatch(fetchAllUsers(data));
    }
  };
  /**
   * Modal box close function
   */
  const closeModal = () => {
    setModalView({
      status: false,
      title: '',
      header: false,
      content: <div />,
      rightCorner: '',
    });
  };

  /**
   * User Delete function
   */
  const deleteFunction = (currentData: String) => {
    setOpenConfirm(true);
    setAccountId(currentData);
  };

  /**
   * The delete the user confirm function
   */
  const deleteConfirmFunction = () => {
    setOpenConfirm(false);
    userApi
      .deleteUser(accountId)
      .then(() => {
        fetchData({
          limit: DefaultApiParameters.limit,
          page: DefaultApiParameters.page,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /**
   * Table pagination function
   * @param value
   */
  const pageNateLimitChange = (value: ApiRequest) => {
    fetchData(value);
  };

  /**
   * User filter function
   */
  const filterFunction = () => {
    setModalView({
      status: true,
      title: 'Add Account',
      header: false,
      content: <FilterUser closeModal={closeModal} />,
      rightCorner: 'filter-modal',
    });
  };

  /**
   * User filter clear function
   */
  const filterClearFunction = () => {
    setOpenConfirm(true);
  };

  /**
   * User details view function
   */
  const viewFunction = (currentData: String) => {
    setActiveLogModal(true);
    console.log(currentData);
  };

  const column = [
    {
      label: 'Date',
      accessorKey: 'date' as keyof UserInterFace,
    },
    {
      label: 'Consumer Id',
      accessorKey: 'name' as keyof UserInterFace,
    },
    {
      label: 'Consumer Type',
      accessorKey: 'accountId' as keyof UserInterFace,
    },
    {
      label: 'Email',
      accessorKey: 'roleName' as keyof UserInterFace,
    },
    {
      label: 'Phone Number',
      accessorKey: 'email' as keyof UserInterFace,
    },
    {
      label: 'Payment Method',
      accessorKey: 'email' as keyof UserInterFace,
    },
    {
      label: 'Wallet Balance',
      accessorKey: 'email' as keyof UserInterFace,
    },
    {
      label: 'Status',
      accessorKey: 'status' as keyof UserInterFace,
      position: 'center',
      /**
       * Data custom function
       * @param currentRow
       */
      customData: (currentRow: UserInterFace) =>
        currentRow.status ? (
          <div className="tag-success-label">
            <CheckCircle /> <span>Active</span>
          </div>
        ) : (
          <div className="tag-danger-label">
            <DotCircle /> <span>Inactive</span>
          </div>
        ),
    },
  ];

  useEffect(() => {
    if (users.length === 0) {
      fetchData({
        limit,
        page,
      });
    }
  }, []);

  return (
    <div className="table-content">
      <ModalBox
        open={modalView.status}
        title={modalView.title}
        content={modalView.content}
        closeModal={closeModal}
        header={modalView.header}
        rightCorner={modalView.rightCorner}
      />
      <ConfirmBox
        open={openConfirm}
        title="Are you sure want to delete this user?"
        subTitle="Deleting your account is permanent and cannot be undone. All the data, settings, and transaction history will be lost. Are you sure you want to proceed?"
        closeModal={() => {
          setOpenConfirm(false);
          setAccountId('');
        }}
        submitButton={
          <MyButton
            label="Delete"
            buttonType="error"
            onClickFunc={deleteConfirmFunction}
            svgIcon={<DeleteIcon />}
          />
        }
      />
      {openTab === 0 && (
        <CustomTable
          header={column}
          data={users}
          serialNo
          actionButton
          filterFunction={filterFunction}
          filterClearFunction={filterClearFunction}
          sizeChangeButtonRequired
          height={432}
          page={page}
          limit={limit}
          loading={loading}
          totalPages={totalPages}
          totalResults={totalResults}
          pageLimitChange={pageNateLimitChange}
          actionButtonList={[
            {
              icon: <ActivityIcon />,
              function: viewFunction,
              color: 'view-color',
            },
            {
              icon: <DeleteIcon />,
              function: deleteFunction,
              color: 'delete-color',
            },
          ]}
          footerLeft={
            <div className="table-bottom-button-list">
              <div className="table-button-item">
                <MyButton
                  label="Export Data"
                  buttonType="back"
                  onClickFunc={() => {}}
                  svgIcon={<Export />}
                />
              </div>
            </div>
          }
          rightTopContent={
            <div className="table-top-button-list">
              <div className="table-button-item">
                <MyButton
                  label=""
                  buttonType="primary-outline"
                  onClickFunc={() => setOpenTab(0)}
                  svgIcon={<TableList />}
                />
              </div>
              <div className="table-button-item">
                <MyButton
                  label=""
                  buttonType="primary-outline"
                  onClickFunc={() => setOpenTab(1)}
                  svgIcon={<GridList />}
                />
              </div>
            </div>
          }
        />
      )}
      {openTab === 1 && (
        <div className="card-details">
          <TopHeaderButtons
            filterFunction={filterFunction}
            filterClearFunction={filterClearFunction}
            sizeChangeFunction={() => {}}
            rightTopContent={
              <div className="table-top-button-list">
                <div className="table-button-item">
                  <MyButton
                    label=""
                    buttonType="primary-outline"
                    onClickFunc={() => setOpenTab(0)}
                    svgIcon={<TableList />}
                  />
                </div>
                <div className="table-button-item">
                  <MyButton
                    label=""
                    buttonType="primary-outline"
                    onClickFunc={() => setOpenTab(1)}
                    svgIcon={<GridList />}
                  />
                </div>
              </div>
            }
          />
          <div className="card-list consumer-list">
            {users.map((value: UserInterFace) => (
              <CardBox className="height-add">
                <ConsumerCardBody
                  userDetails={value}
                  activeLog={viewFunction}
                  editFunction={() => {}}
                  menuList={[
                    {
                      content: (
                        <div className="text-danger flex-row">
                          {' '}
                          <DeleteIcon /> Delete
                        </div>
                      ),
                      function: deleteFunction,
                    },
                  ]}
                />
              </CardBox>
            ))}
          </div>
          <div className="pagination">
            <Pagination
              active={page}
              setActive={pageNateLimitChange}
              size={totalPages}
              totalResults={totalResults}
              limit={limit}
            />
          </div>
        </div>
      )}
      <ActiveLogCard closeModal={() => setActiveLogModal(false)} activeLogModal={activeLogModal} />
    </div>
  );
}

export default UsersList;
