import { ReactComponent as Export } from '../../assets/icons/export.svg';
import MyButton from '../../shared-components/button/Button';
import CustomToggleButton from '../../shared-components/custom-toggle-button/CustomToggleButton';

import ReportGraphChart from './components/report-graph';
import UsersList from './components/users-list';

/**
 * User Management page function component
 */
function EndConsumerManagement() {
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <div className="container">
        <div className="header-title">
          <h2>End Consumer Management</h2>
        </div>
        <div className="body-container end-consumer-warp">
          <div className="table-content">
            <div className="reports-graph-content">
              <div className="reports-graph-header-container">
                <div className="reports-title">Application Status</div>
                <div className="reports-graph-header">
                  <div className="reports-toggle-btn">
                    <CustomToggleButton
                      labels={['12 Months', 'Last Month', '7 Days', '24 hours']}
                      onToggle={() => {}}
                      defaultActiveIndex={0}
                    />
                  </div>
                  <div className="reports-export-btn">
                    <MyButton
                      label="Export Data"
                      buttonType="back"
                      disableHoverEffect
                      onClickFunc={() => {}}
                      svgIcon={<Export />}
                    />
                  </div>
                </div>
              </div>
              <div className="reports-graph">
                <ReportGraphChart />
              </div>
            </div>
            <UsersList />
          </div>
        </div>
      </div>
    </div>
  );
}

export default EndConsumerManagement;
