import { ReactComponent as ActivityIcon } from '../../../assets/icons/activety-icon.svg';
import { ReactComponent as CreditCard } from '../../../assets/icons/credit-card.svg';
import { ReactComponent as CurrencyIcon } from '../../../assets/icons/currency-icon.svg';
import { ReactComponent as DotCircle } from '../../../assets/icons/dot-circle.svg';
import { ReactComponent as MoreIcon } from '../../../assets/icons/more-icon.svg';
import { UserCardDetails } from '../../../interface/users-management.interface';
import MyButton from '../../../shared-components/button/Button';
import DropDown from '../../../shared-components/drop-down/DropDown';

/**
 * User Card Body page function component 
 */
function ConsumerCardBody({ userDetails, activeLog, menuList }: UserCardDetails) {
  return (
    <div className="card-body">
      <div className="card-header">
        <div className="card-header-item-title">
          <div className="title">{userDetails.name}</div>
          <div className="sub-title">{userDetails.accountId}</div>
        </div>
        <div className="card-header-item-tag">
          {userDetails.status ? (
            <div className="tag-success-label">
              <DotCircle /> Active
            </div>
          ) : (
            <div className="tag-danger-label">
              <DotCircle /> Inactive
            </div>
          )}
          
        </div>
        <div className="card-header-item-icon">
          <DropDown list={menuList} icon={<MoreIcon />} id={userDetails.id} />
          
        </div>
      </div>
      <div className="card-body-content">
        <div className="user-details">
          <div className="title">Consumer Type</div>
          <div className="value">{userDetails.roleName}</div>
        </div>
        <div className="user-details">
          <div className="title">Date</div>
          <div className="value">{userDetails.date}</div>
        </div>
        <div className="user-details">
          <div className="title">Email</div>
          <div className="value">{userDetails.email}</div>
        </div>
        <div className="user-details">
          <div className="title">Phone Number</div>
          <div className="value">{userDetails.email}</div>
        </div>
        <div className="user-details">
          <div className="title">Payment Method</div>
          <div className="value">
            <CreditCard /> {userDetails.email}
          </div>
        </div>
        <div className="user-details">
          <div className="title">Wallet Balance</div>
          <div className="value">
            <CurrencyIcon /> {userDetails.email}
          </div>
        </div>
      </div>
      <div className="card-body-footer">
        <MyButton
          label="Activity Log"
          buttonType="back"
          svgIcon={<ActivityIcon />}
          onClickFunc={() => activeLog(userDetails.id)}
        />
      </div>
    </div>
  );
}

export default ConsumerCardBody;
