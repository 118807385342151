import React, { useState, useRef, useEffect } from 'react';

import { ReactComponent as CloseIcon } from '../../assets/icons/close-circle.svg';
import { ReactComponent as EyeIcon } from '../../assets/icons/eye-icon.svg';
import image1 from '../../assets/images/profiles/profile.jpg';

interface CustomInputProperties {
  inputLabel: string;
}
/**
 * Image Viewer function component
 */
function ImageViewer({
  inputLabel,
}: CustomInputProperties) {
  const reference = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState('');
  useEffect((): void => {
    /**
     * Click handle for outside function
     */
    const handleClickOutside = (event: MouseEvent) => {
      if (reference.current && !reference.current.contains(event.target as Node)) {
        setOpen('');
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
  }, []);
  // console.log(Math.abs(2 % 2) === 1);
  

  return (
    <div className="custom-input-wrap">
      {inputLabel && (
        <p className="input-label">
          {inputLabel}
        </p>
      )}
      <div className="custom-input-container">
        <div className="images-list">
          <div className="image-item"><img src={image1} alt="photos" /></div>
          <div className="image-item"><img src={image1} alt="photos" /></div>
          <div className="image-item"><img src={image1} alt="photos" /></div>
          <div className="image-item"><img src={image1} alt="photos" /></div>
          <div className="image-item count">+4</div>
        </div>
        <span role="presentation" onClick={() => setOpen('open')}>
          <EyeIcon />
        </span>
      </div>
      <div className={`full-page-content ${open}`}>
        <div className="image-modal-box" ref={reference}>
          <div className="modal-header">
            <div className="modal-title">Media</div>
            <div className="close-icon" role="presentation" onClick={() => setOpen('')}><CloseIcon /></div>
          </div>
          <div className="modal-body">
            <div className="image-grid">
              <div className="image-item item-1"><img src={image1} alt="photos" /></div>
              <div className="image-item"><img src={image1} alt="photos" /></div>
              <div className="image-item item-1"><img src={image1} alt="photos" /></div>
              <div className="image-item"><img src={image1} alt="photos" /></div>
              <div className="image-item item-1"><img src={image1} alt="photos" /></div>
              <div className="image-item"><img src={image1} alt="photos" /></div>
              <div className="image-item item-1"><img src={image1} alt="photos" /></div>
              <div className="image-item"><img src={image1} alt="photos" /></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ImageViewer;
