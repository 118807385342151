import React, { useEffect, useRef } from 'react';

import { ReactComponent as Calender } from '../../../assets/icons/calender.svg';
import { ReactComponent as CheckCircle } from '../../../assets/icons/check-circle.svg';
import { ReactComponent as CloseCircle } from '../../../assets/icons/close-circle.svg';
import { ReactComponent as DotCircle } from '../../../assets/icons/dot-circle.svg';

interface AddRoleInterface {
  closeModal: () => void;
  activeLogModal: boolean;
}
/**
 * Role Add page function component
 */
function ActiveLogCard({ activeLogModal, closeModal }: AddRoleInterface) {
  const reference = useRef<HTMLDivElement | null>(null);
  /**
      * tab close function
    */
  const handleClickOutside = (event: MouseEvent) => {
    if (reference.current && !reference.current.contains(event.target as Node)) {
      closeModal();
    }
  };
  useEffect((): void => {
    document.addEventListener('mousedown', handleClickOutside);
  }, []);
  return (
    <div className={`active-log-card-warp ${activeLogModal && 'open'}`} ref={reference}>
      <div className="active-log-card-body">
        <div className="close-icon" onClick={closeModal} role="presentation">
          <CloseCircle />
        </div>
        <div className="active-log-content">
          <div className="active-logs-list">
            <div className="active-loge-item">
              <div className="icon line">
                <DotCircle />
              </div>
              <div className="details">
                <div className="calender-icon">
                  <Calender />
                </div>
                <div className="calender-details">
                  <div className="date">21-08-2024</div>
                  <div className="time-list">
                    <div className="time-item">
                      <div className="title">10:00 AM</div>
                      <div className="sub-title">
                        Sign UP{' '}
                        <div className="text-success">
                          <CheckCircle />
                        </div>
                      </div>
                    </div>
                    <div className="time-item">
                      <div className="title">10:00 AM</div>
                      <div className="sub-title">
                        Sign UP{' '}
                        <div className="text-success">
                          <CheckCircle />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="active-loge-item">
              <div className="icon line">
                <DotCircle />
              </div>
              <div className="details">
                <div className="calender-icon">
                  <Calender />
                </div>
                <div className="calender-details">
                  <div className="date">21-08-2024</div>
                  <div className="time-list">
                    <div className="time-item">
                      <div className="title">10:00 AM</div>
                      <div className="sub-title">
                        Sign UP{' '}
                        <div className="text-success">
                          <CheckCircle />
                        </div>
                      </div>
                    </div>
                    <div className="time-item">
                      <div className="title">10:00 AM</div>
                      <div className="sub-title">
                        Sign UP{' '}
                        <div className="text-success">
                          <CheckCircle />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="active-loge-item">
              <div className="icon">
                <DotCircle />
              </div>
              <div className="details">
                <div className="calender-icon">
                  <Calender />
                </div>
                <div className="calender-details">
                  <div className="date">21-08-2024</div>
                  <div className="time-list">
                    <div className="time-item">
                      <div className="title">10:00 AM</div>
                      <div className="sub-title">
                        Sign UP{' '}
                        <div className="text-success">
                          <CheckCircle />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ActiveLogCard;
