import React from 'react';

import { ReactComponent as MoreIcon } from '../../assets/icons/more-icon.svg';
import { ApiRequest } from '../../interface/common.interface';
import BatteryLoader from '../battery-loader/BatteryLoader';
import DropDownMenu from '../drop-down/DropDownMenu';
import Pagination from '../pagination/Pagination';
import TopHeaderButtons from '../top-header-buttons/TopHeaderButtons';

type RowWithId = { id: string }; // Ensure id is either string or number
type RowDataTypes = string | number | null;

// Define ColumnHeaderProps to be generic and flexible for any data row structure
type ColumnHeaderProperties<T> = {
  label: string;
  position?: string;
  customLabel?: JSX.Element;
  accessorKey: keyof T; // Tied to the keys of the data row (T)
  customData?: (currentRow: T) => JSX.Element; // Custom rendering function for the data
};

interface CustomTableProperties<T extends RowWithId> {
  header: ColumnHeaderProperties<T>[]; // Header configuration for the table
  data: T[]; // Array of data representing rows in the table
  footerLeft?: JSX.Element;
  rightTopContent?: JSX.Element;
  height?: number;
  page?: number;
  limit?: number;
  totalPages?: number;
  totalResults?: number;
  serialNo?: boolean;
  loading?: boolean;
  actionButtonList?: {
    color: String;
    function: (currentData: String, event?: React.MouseEvent) => void;
    icon: JSX.Element;
  }[];
  actionDropDownList?: {
    function: (value: String) => void;
    content: JSX.Element;
  }[];
  actionButton?: boolean;
  actionDropdown?: boolean;
  filterFunction?: () => void;
  filterClearFunction?: () => void;
  pageLimitChange?: (value: ApiRequest) => void;
  sizeChangeButtonRequired?: boolean;
}

/**
 * CustomTable component
 */
function CustomTable<T extends RowWithId>(properties: CustomTableProperties<T>) {
  const fullHeight = document.body.clientHeight;
  const {
    header,
    data,
    footerLeft,
    loading,
    height = 0,
    page = 0,
    limit = 0,
    totalPages = 0,
    totalResults = 0,
    pageLimitChange = () => {},
    serialNo,
    rightTopContent,
    actionButton,
    actionDropdown,
    actionButtonList = [],
    actionDropDownList = [],
    sizeChangeButtonRequired,
    filterFunction = () => {},
    filterClearFunction = () => {},
  } = properties;

  /**
   * Change the page function
   * @param pageNo
   * @param limitCount
   */
  const changePage = (changeDate: ApiRequest) => {
    pageLimitChange(changeDate);
  };

  return (
    <div className="custom-table-wrap">
      <TopHeaderButtons
        filterFunction={filterFunction}
        filterClearFunction={filterClearFunction}
        sizeChangeButtonRequired={sizeChangeButtonRequired}
        sizeChangeFunction={() => {}}
        rightTopContent={rightTopContent}
      />
      <div className="custom-table">
        <div className="table-container">
          <div
            className="table-header"
            style={
              {
                '--header-length': header?.length || 1,
              } as React.CSSProperties
            }>
            {serialNo && <div className="each-table-header serial">S. No</div>}
            {header.map((col) => (
              <div className="each-table-header" key={col.accessorKey as string}>
                {col.customLabel ? col.customLabel : col.label}
              </div>
            ))}
            {actionButton && <div className="each-table-header">Action</div>}
          </div>
          <div className="table-body" style={{ maxHeight: `${fullHeight - height}px` }}>
            {loading && (<div className="table-loading">
              <BatteryLoader />
            </div>)}
            {!loading && data.length === 0 && (<div className="table-loading">
              No Data Found
            </div>)}

            {!loading && data.length > 0 && data.map((row, index) => (
              <div
                className="table-row"
                key={row.id}
                style={
                  {
                    '--header-length': header?.length || 1,
                  } as React.CSSProperties
                }>
                {serialNo && <div className="table-row-data serial">{index + 1}</div>}
                {header.map((col) => (
                  <div className={`table-row-data ${col.position}`} key={col.accessorKey as string}>
                    {col.customData
                      ? col.customData(row)
                      : // Use type assertion here to indicate that row[col.accessorKey] is valid for rendering
                        (row[col.accessorKey] as RowDataTypes as React.ReactNode)}
                  </div>
                ))}
                {actionButton && (
                  <div className="table-row-data">
                    {actionDropdown ? (
                      <div className="action-button">
                        <DropDownMenu
                          icon={<MoreIcon />}
                          list={actionDropDownList.map(
                            (items: {
                              function: (value: String) => void;
                              content: JSX.Element;
                            }) => ({
                              ...items,
                              id: row.id,
                            }),
                          )}
                        />
                      </div>
                    ) : (
                      <div className="action-button-group">
                        {actionButtonList.map(
                          (action: {
                            color: String;
                            function: (currentData: string) => void;
                            icon: JSX.Element;
                          }) => (
                            <div
                              className={`action-button-item ${action.color}`}
                              onClick={() => action.function(row.id)}
                              role="presentation">
                              {action.icon}
                            </div>
                          ),
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="custom-table-footer">
        <div className="footer-left">{footerLeft}</div>
        <div className="footer-right">
          <Pagination
            active={page}
            setActive={changePage}
            size={totalPages}
            limit={limit}
            totalResults={totalResults}
          />
        </div>
      </div>
    </div>
  );
}

CustomTable.defaultProps = {
  footerLeft: null,
  rightTopContent: null,
  serialNo: false,
  loading: false,
  actionButton: false,
  actionDropdown: false,
  sizeChangeButtonRequired: false,
};

export default CustomTable;
