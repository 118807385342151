import React, { useRef } from 'react';

import { ReactComponent as Calender } from '../../assets/icons/calender.svg';

interface CustomDateRangeProperties {
  inputLabel?: string;
  value1: string | number;
  value2: string | number;
  name1: string;
  name2: string;
  onChangeValue: (name: string, value: string | number) => void;
  valueRequired?: boolean;
  readOnly?: boolean;
}

/**
 * Icon click function
 */
const handleInputClick = (reference: React.RefObject<HTMLInputElement>) => {
  console.log(reference);
  reference.current?.showPicker();
};

/**
 * Custom Date Range function component
 */
function CustomDateRange({
  inputLabel,
  name1,
  name2,
  value1,
  value2,
  onChangeValue,
  valueRequired,
  readOnly,
}: CustomDateRangeProperties) {
  const fromDateInputReference = useRef(null);
  const toDateInputReference = useRef(null);
  /**
   * Date Range field handle function
   */
  const handleValueChange = (name: string, changeValue: string | number) => onChangeValue(name, changeValue);


  return (
    <div className="custom-input-date-wrap">
      {inputLabel && (
        <p className="input-label">
          {inputLabel}
          {valueRequired && <span>*</span>}
        </p>
      )}
      <div className="custom-date-input">
        <div className="custom-input-container">
          <input
            type="date"
            placeholder="From"
            value={value1}
            onChange={(error) => handleValueChange(name1, error.target.value)}
            required={valueRequired}
            readOnly={readOnly}
            ref={fromDateInputReference}
            onClick={() => handleInputClick(fromDateInputReference)}
          />
          <span role="presentation" onClick={() => handleInputClick(fromDateInputReference)}>
            <Calender />
          </span>
        </div>
        <div className="custom-input-container">
          <input
            type="date"
            placeholder="To"
            value={value2}
            onChange={(error) => handleValueChange(name2, error.target.value)}
            required={valueRequired}
            readOnly={readOnly}
            ref={toDateInputReference}
            onClick={() => handleInputClick(toDateInputReference)}
          />
          <span role="presentation" onClick={() => handleInputClick(toDateInputReference)}>
            <Calender />
          </span>
        </div>
      </div>
    </div>
  );
}

CustomDateRange.defaultProps = {
  inputLabel: '',
  valueRequired: false,
  readOnly: false,
};

export default CustomDateRange;
