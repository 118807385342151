
import { ReactComponent as CheckCircle } from '../../assets/icons/check-circle.svg';
import { ReactComponent as CloseCircle } from '../../assets/icons/close-circle.svg';
/**
 * Card box function component
 */
function NotificationBox() {
  return (
    <div className="alert-notification-warp">
      <div className="alert-notification success">
        <div className="alert-content">
          <div className="alert-icon"><CheckCircle /></div>
          <div className="alert-message">Login Successfully...</div>
          <div className="alert-close"><CloseCircle /></div>
        </div>
      </div>
    </div>
  );
}

// CardBox.defaultProps = {
//   inputLabel: '',
//   valueRequired: false,
// };

export default NotificationBox;
