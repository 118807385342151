import React, { useState } from 'react';

import { RoleAddInterFace, RoleInterFace } from '../../../interface/role-management.interface';
import MyButton from '../../../shared-components/button/Button';
import CheckBoxButtons from '../../../shared-components/check-box-buttons/CheckBoxButtons';
import CustomInput from '../../../shared-components/custom-input/CustomInput';
import roleApi from '../../../utils/api/roles';

interface AddRoleInterface {
  closeModal: () => void;
  roleDetail?: RoleInterFace;
  completedTask: (message: string, type: string) => void;
}
/**
 * Role Add page function component
 */
function AddRole({ closeModal, roleDetail, completedTask }: AddRoleInterface) {
  const permissions = [
    {
      label: 'Permission 1',
      value: 'permission1',
    },
    {
      label: 'Permission 2',
      value: 'permission2',
    },
    {
      label: 'Permission 3',
      value: 'permission3',
    },
  ];

  const [roleDetails, setRoleDetails] = useState<RoleAddInterFace>({
    name: roleDetail?.name || '',
    responsibility: roleDetail?.responsibility || '',
    permissions: roleDetail?.permissions || [],
  });

  /**
   * Handle the input change function
   * @param name
   * @param value
   */
  const handleInputChange = (name: string, value: string | number | (string | number)[]) => {
    setRoleDetails((previous) => ({
      ...previous,
      [name]: value,
    }));
  };

  /**
   * Handle the submit function
   */
  const handleSubmit = () => {
    const request = {
      name: roleDetails.name,
      responsibility: roleDetails.responsibility,
      permissions: roleDetails.permissions,
    };
    if (roleDetail?.id) {
      roleApi
        .editRoles(roleDetail?.id, request)
        .then(() => {
          completedTask('Role Updated Successfully', 'role');
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      roleApi
        .createRoles(request)
        .then(() => {
          completedTask('Role Added Successfully', 'role');
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <div className="add-account-form">
      <div className="form-body">
        <div className="form-field">
          <CustomInput
            name="name"
            inputLabel="Role Name"
            type="text"
            placeholder="Enter the name"
            value={roleDetails.name}
            onChangeValue={handleInputChange}
          />
        </div>
        <div className="form-field">
          <CustomInput
            name="responsibility"
            inputLabel="Responsibility"
            type="text"
            placeholder="Enter the Details"
            value={roleDetails.responsibility}
            onChangeValue={handleInputChange}
          />
        </div>
        <div className="form-field">
          <CheckBoxButtons
            name="permissions"
            list={permissions}
            inputLabel="Permissions"
            value={roleDetails.permissions}
            onChangeValue={handleInputChange}
          />
        </div>
        <div className="form-field table-bottom-button-list form-bottom-button end">
          <div className="table-button-item">
            <MyButton label="Cancel" buttonType="back" onClickFunc={closeModal} />
          </div>
          <div className="table-button-item">
            <MyButton label="Submit" buttonType="submit" onClickFunc={handleSubmit} />
          </div>
        </div>
      </div>
    </div>
  );
}

AddRole.defaultProps = {
  roleDetail: {},
};

export default AddRole;
