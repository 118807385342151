import React, { useEffect, useState } from 'react';

import DefaultApiParameters from '../../../enum/DefaultApiParameters';
import {
  AccountAddState,
  AccountParameters,
  AccountsInterFace,
} from '../../../interface/account-management.interface';
import { useAppDispatch } from '../../../redux/hooks';
import { fetchAllAccounts } from '../../../redux/slices/AccountManagementSlice';
import MyButton from '../../../shared-components/button/Button';
import CustomFileInput from '../../../shared-components/custom-file-input/CustomFileInput';
import CustomInput from '../../../shared-components/custom-input/CustomInput';
import CustomPhoneInput from '../../../shared-components/custom-input/CustomPhoneInput';
import MultiSelectDropdown from '../../../shared-components/multi-select-field/MultiSelectField';
import accountApi from '../../../utils/api/account';

/**
 * Add Account function component
 */
function AddAccount({
  closeModal,
  currentData,
}: {
  closeModal: () => void;
  currentData?: AccountsInterFace;
}) {
  const dispatch = useAppDispatch();
  const [disabled, setDisabled] = useState(false);
  const [accountDetails, setAccountDetails] = useState<AccountAddState>({
    status: [],
    name: '',
    medias: [],
    email: '',
    phone: '',
    password: '',
    rePassword: '',
  });

  /**
   * Fetch the one account data function
   * @param id
   */
  const fetchData = (id: string) => {
    accountApi
      .fetchOneAccounts(id)
      .then((response) => {
        setAccountDetails({
          status: [],
          name: response.data.name,
          medias: [],
          email: response.data.email,
          phone: response.data.phoneNumber,
          password: '',
          rePassword: '',
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /**
   * Handle the change function
   * @param name
   * @param value
   * @returns
   */
  const handleChangeFunction = (name: string, value: string[] | File[] | string | number) => {
    setAccountDetails((previous) => ({
      ...previous,
      [name]: value,
    }));
  };

  /**
   * Handle the submit function
   */
  const handleSubmit = () => {
    let errorMessage = false;
    if (!currentData?.id) {
      if (
        accountDetails.phone === '' ||
        accountDetails.name === '' ||
        accountDetails.email === '' ||
        accountDetails.password === '' ||
        accountDetails.rePassword !== accountDetails.password
      ) {
        errorMessage = true;
      }
      if (!errorMessage) {
        setDisabled(true);
        const request: AccountParameters = {
          name: accountDetails.name,
          email: accountDetails.email,
          password: accountDetails.password,
          phoneNumber: accountDetails.phone,
        };
        accountApi
          .createAccounts(request)
          .then(() => {
            dispatch(
              fetchAllAccounts({
                page: DefaultApiParameters.page,
                limit: DefaultApiParameters.limit,
              }),
            );
            closeModal();
          })
          .catch((error) => {
            console.log(error);
            setDisabled(false);
          });
      }
    } else {
      if (
        accountDetails.phone === '' ||
        accountDetails.name === '' ||
        accountDetails.email === ''
      ) {
        errorMessage = true;
      }
      if (!errorMessage) {
        setDisabled(true);
        const request: AccountParameters = {
          name: accountDetails.name,
          email: accountDetails.email,
          phoneNumber: accountDetails.phone,
        };
        accountApi
          .editAccounts(currentData?.tenantId, request)
          .then(() => {
            dispatch(
              fetchAllAccounts({
                page: DefaultApiParameters.page,
                limit: DefaultApiParameters.limit,
              }),
            );
            closeModal();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };

  useEffect(() => {
    if (currentData?.id) {
      fetchData(currentData?.tenantId);
    }
  }, []);

  return (
    <div className="add-account-form">
      <div className="form-body">
        <div className="form-field">
          <MultiSelectDropdown
            name="status"
            inputLabel="User Type"
            onChangeValue={handleChangeFunction}
            placeholder="All"
            options={[
              {
                label: 'CPO',
                value: 'cpo',
              },
              {
                label: 'Distributor',
                value: 'distributor',
              },
              {
                label: 'Fleet Manager',
                value: 'fleetManager',
              },
              {
                label: 'Manufacturer',
                value: 'manufacturer',
              },
            ]}
            selectedValues={accountDetails.status}
          />
        </div>
        <div className="form-field">
          <CustomInput
            name="name"
            inputLabel="User Name"
            type="text"
            placeholder="Enter User Name"
            value={accountDetails.name}
            onChangeValue={handleChangeFunction}
          />
        </div>
        <div className="form-field">
          <CustomFileInput
            name="medias"
            inputLabel="Government Id"
            placeholder="Upload here..."
            selectedFiles={accountDetails.medias}
            onChangeValue={handleChangeFunction}
            acceptedFileType=""
          />
        </div>
        <div className="form-field">
          <CustomInput
            name="email"
            inputLabel="Email Id"
            type="text"
            placeholder="Enter Email"
            value={accountDetails.email}
            onChangeValue={handleChangeFunction}
          />
        </div>
        <div className="form-field">
          <CustomPhoneInput
            name="phone"
            inputLabel="Phone Number"
            type="number"
            placeholder="Enter Phone Number"
            value={accountDetails.phone}
            onChangeValue={handleChangeFunction}
          />
        </div>
        {!currentData?.id && (
          <>
            <div className="form-field">
              <CustomInput
                name="password"
                inputLabel="Set Password"
                type="password"
                placeholder="Enter Password"
                value={accountDetails.password}
                onChangeValue={handleChangeFunction}
              />
            </div>
            <div className="form-field">
              <CustomInput
                name="rePassword"
                inputLabel="Re-Enter Password"
                type="password"
                placeholder="Enter Password"
                value={accountDetails.rePassword}
                onChangeValue={handleChangeFunction}
              />
            </div>
          </>
        )}
        <div className="form-field table-bottom-button-list form-bottom-button">
          <div className="table-button-item">
            <MyButton label="Back" buttonType="back" onClickFunc={closeModal} />
          </div>
          <div className="table-button-item">
            <MyButton
              label="Submit"
              buttonType="submit"
              disabled={disabled}
              onClickFunc={handleSubmit}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

AddAccount.defaultProps = {
  currentData: {},
};

export default AddAccount;
