import { useContext } from 'react';

import ThemeContext from '../../contexts/theme-context/ThemeContext';

/**
 * Dashboard function Component
 */
function Dashboard() {
  const { theme, setTheme } = useContext(ThemeContext);

  /**
   * Change Theme function
   */
  const changeTheme = () => {
    if (theme === 'light') {
      setTheme('dark');
    } else {
      setTheme('light');
    }
  };

  return (
    <div className="dashboard-wrap">
      <h1>Shared Components</h1>
      <h1 role="presentation" onClick={changeTheme}>
        Change Theme
      </h1>
    </div>
  );
}

export default Dashboard;
