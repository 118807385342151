import { configureStore } from '@reduxjs/toolkit';

import AccountManagementReducer from './slices/AccountManagementSlice';
import ApprovedStationManagementReducer from './slices/ApprovedStationManagementSlice';
import PaymentManagementReducer from './slices/PaymentManagementSlice';
import RejectedStationManagementReducer from './slices/RejectedStationManagementSlice';
import RoleManagementReducer from './slices/RolesManagementSlice';
import UnapprovedStationManagementReducer from './slices/UnapprovedStationManagementSlice';
import UserManagementReducer from './slices/UsersManagementSlice';

// Store configure with the reducer
export const store = configureStore({
  reducer: {
    accounts: AccountManagementReducer,
    payments: PaymentManagementReducer,
    users: UserManagementReducer,
    roles: RoleManagementReducer,
    approvedStation: ApprovedStationManagementReducer,
    unapprovedStation: UnapprovedStationManagementReducer,
    rejectedStation: RejectedStationManagementReducer,
  },
});

// RootState and AppDispatch types
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
