import React, { useRef } from 'react';

import { ReactComponent as DocumentIcon } from '../../assets/icons/document-file.svg';
import { ReactComponent as FileUploadIcon } from '../../assets/icons/file-icon.svg';
import { ReactComponent as RemoveFileIcon } from '../../assets/icons/remove-icon.svg';
import { MultiMediaData } from '../../interface/account-management.interface';

interface CustomInputProperties {
  inputLabel?: string;
  acceptedFileType: string;
  placeholder: string;
  selectedFiles: File[] | null;
  name: string;
  onChangeValue: (name: string, file: File[]) => void;
  valueRequired?: boolean;
  readOnly?: boolean;
  unselectHandler?: (
    name: string,
    url: string,
    indexToRemove: number,
    type: 'newFile' | 'existingFile',
  ) => void;
  existingName?: string;
  existingFiles?: MultiMediaData[] | null;
}

/**
 * File upload input box
 */
function CustomFileInput({
  inputLabel,
  acceptedFileType,
  name,
  placeholder,
  selectedFiles,
  onChangeValue,
  valueRequired,
  readOnly,
  unselectHandler,
  existingName,
  existingFiles,
}: CustomInputProperties) {
  const inputReference = useRef<HTMLInputElement | null>(null);
  /**
   * Function to handle select file
   */
  const handleSelectFile = (selectedFileList: Array<File>) => {
    if (selectedFileList?.length) {
      onChangeValue(name, selectedFileList);
    }
  };

  /**
   * Function to handle file unselect
   * @param indexToRemove
   */
  const handleRemoveSelectedFile = (
    url: string,
    indexToRemove: number,
    type: 'newFile' | 'existingFile',
  ) => {
    if (unselectHandler && type === 'newFile') {
      unselectHandler(name, url, indexToRemove, type);
    } else if (unselectHandler && type === 'existingFile' && existingName) {
      unselectHandler(existingName, url, indexToRemove, type);
    } else {
      const afterRemovedSelectedFile =
        selectedFiles?.filter((_, index: number) => index !== indexToRemove) || [];
      onChangeValue(name, afterRemovedSelectedFile);
    }
  };

  return (
    <div className="custom-file-input-wrap">
      {inputLabel && (
        <p className="input-label">
          {inputLabel}
          {valueRequired && <span>*</span>}
        </p>
      )}
      <div className="custom-file-input-container">
        <div className="selected-file-wrap">
          {existingFiles?.length
            ? existingFiles.map((existingFile, index) => (
                <div className="single-selected-file">
                  <span className="doc-icon">
                    <DocumentIcon />
                  </span>
                  <p>{existingFile?.fileName}</p>
                  <span
                    className="remove-icon"
                    role="presentation"
                    onClick={() =>
                      handleRemoveSelectedFile(existingFile.url, index, 'existingFile')
                    }>
                    <RemoveFileIcon />
                  </span>
                </div>
              ))
            : null}
          {(() => {
            let selectedFileView;
            if (selectedFiles?.length) {
              selectedFileView = selectedFiles.map((selectedFile, index) => (
                <div className="single-selected-file">
                  <span className="doc-icon">
                    <DocumentIcon />
                  </span>
                  <p>{selectedFile?.name}</p>
                  <span
                    className="remove-icon"
                    role="presentation"
                    onClick={() => handleRemoveSelectedFile('', index, 'newFile')}>
                    <RemoveFileIcon />
                  </span>
                </div>
              ));
            } else if (
              (selectedFiles?.length === 0 || !selectedFiles) &&
              (existingFiles?.length === 0 || !existingFiles)
            ) {
              selectedFileView = <p className="placeholder">{placeholder}</p>;
            }
            return selectedFileView;
          })()}
        </div>
        <input
          ref={inputReference}
          type="file"
          accept={acceptedFileType}
          multiple
          style={{ display: 'none' } as React.CSSProperties}
          onChange={(event) => {
            const files = event?.target?.files;
            if (files) {
              handleSelectFile(Array.from(files));
            }
          }}
        />
        <div className="file-input-icon-wrap">
          <span
            className={`file-input-icon ${readOnly ? 'disabled' : ''} `}
            role="presentation"
            onClick={() => {
              if (!readOnly) {
                inputReference.current?.click();
              }
            }}>
            <FileUploadIcon />
          </span>
        </div>
      </div>
    </div>
  );
}

CustomFileInput.defaultProps = {
  inputLabel: '',
  valueRequired: false,
  readOnly: false,
  unselectHandler: null,
  existingName: '',
  existingFiles: [],
};

export default CustomFileInput;
