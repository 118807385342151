interface TabMenusProperties {
  menus: { icon?: JSX.Element; name: String; count?: number }[],
  openTab: number,
  setOpenTab: (value: number) => void;
}

/**
 * Tab menu function component
 */
function TabMenus({ menus, openTab, setOpenTab }: TabMenusProperties) {
  return (
    <div className="tab-menus-warp">
      <ul style={{ '--menu-count': menus.length } as React.CSSProperties}>
        {
          menus.map((item, index) => (
            <li id={`button-${index}`} className={openTab === index ? 'tab active' : 'tab'} onClick={() => setOpenTab(index)} role="presentation">
              <div>{item.icon} {item.name} {item.count && (<div className="tag tag-danger">3</div>)}</div>
            </li>
          ))
        }
        <li className="bg" />
      </ul>
    </div>
  );
}

export default TabMenus;
