import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import loginBanner from '../../assets/images/login/login-banner.png';
import sample from '../../assets/videos/background.mp4';
import MyButton from '../../shared-components/button/Button';
import CustomInput from '../../shared-components/custom-input/CustomInput';
import { setCookie } from '../../shared-functions/Cookies';
import { setLocalStorageItem } from '../../shared-functions/LocalStorage';
import authApi from '../../utils/api/auth';

/**
 * Login page function component
 */
function Login() {
  const navigate = useNavigate();
  const [credentials, setCredentials] = useState<{
    emailOrPhoneNumber: string;
    password: string;
  }>({
    emailOrPhoneNumber: '',
    password: '',
  });
  const [disabled, setDisabled] = useState(false);

  /**
   * Input field change function
   * @param name
   * @param changeValue
   */
  const handleCredentialChange = (name: string, changeValue: string | number) => {
    setCredentials({
      ...credentials,
      [name]: changeValue,
    });
  };

  /**
   * handle submit function
   */
  const submitFunction = () => {
    setDisabled(true);
    authApi.login(credentials).then((response) => {
      console.log(response.data);
      const responseData = response?.data;

        // Store access token inside cookies
        setCookie(
          'ev-access-token',
          responseData?.access?.token,
          null,
          responseData?.access?.expires,
        );

        // Store refresh token inside cookies
        setCookie(
          'ev-refresh-token',
          responseData?.refresh?.token,
          null,
          responseData?.refresh?.expires,
        );

        const userVerification = {
          isEmailVerified: responseData?.isEmailVerified,
          isPhoneNumberVerified: responseData?.isPhoneNumberVerified,
        };

        // Set verification in local storage to use in email verification page
        setLocalStorageItem('userVerification', userVerification);
        setDisabled(false);

        navigate('../ev-admin/dashboard');
    }).catch((error) => {
      setDisabled(false);
      console.error('Failed to create role:', error);
    });
  }

  return (
    <div className="login-wrap">
      <video className='videoTag' autoPlay loop muted>
        <source src={sample} type='video/mp4' />
      </video>
      <div className="login-banner-container">
        <img src={loginBanner} alt="banner" className="login-banner" />
      </div>
      <div className="login-form-container">
        <div className="login-form-with-border">
          <div className="login-form">
            <h1>Login</h1>
            <CustomInput
              inputLabel="Email"
              type="email"
              placeholder="Enter the email"
              name="emailOrPhoneNumber"
              value={credentials.emailOrPhoneNumber}
              onChangeValue={handleCredentialChange}
            />
            <div className="password-container">
              <CustomInput
                inputLabel="Password"
                type="password"
                placeholder="Enter password"
                name="password"
                value={credentials.password}
                onChangeValue={handleCredentialChange}
              />
              <div className="forgot-password">
                <span role="presentation" onClick={() => {}}>
                  Forgot Password?
                </span>
              </div>
            </div>
            <MyButton
              label="Submit"
              buttonType="submit"
              onClickFunc={submitFunction}
              disabled={disabled}
            />
          </div>
          {/* <div className="gradient-border">
            <div className="white-bg-color" />
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default Login;
