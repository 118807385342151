import React, { useState, useEffect, useRef } from 'react';

import { ReactComponent as ArrowIcon } from '../../assets/icons/down-arrow.svg';
import { ReactComponent as DownArrowIcon } from '../../assets/icons/up-arrow.svg';


interface Option {
  label: string;
  value: string;
}

interface MultiSelectDropdownProperties {
  options: Option[];
  name: string;
  selectedValues: string[];
  onChangeValue: (name: string, values: string[]) => void;
  inputLabel?: string;
  placeholder?: string;
  icon?: JSX.Element;
}
/**
 * Multi select drop down function
 * @returns 
 */
function MultiSelectDropdown({
  options,
  name,
  selectedValues,
  onChangeValue,
  inputLabel = 'Select Role',
  placeholder = 'Select Option',
  icon,
}: MultiSelectDropdownProperties) {
  const reference = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  /**
   * Handle the change function
   * @param value 
   */
  const handleCheckboxChange = (value: string) => {
    const newSelectedValues = selectedValues.includes(value)
      ? selectedValues.filter((option) => option !== value)
      : [...selectedValues, value];
      onChangeValue(name, newSelectedValues);
  };

  useEffect((): void => {
    /**
     * Click handle function
     */
    const handleClickOutside = (event: any) => {
      if (reference.current && !reference.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className="multi-select-dropdown" >
      <div className="multi-select-header">
        {inputLabel && <span>{inputLabel}</span>}
      </div>
      <div className={`multi-select-dropdown-list ${isOpen ? 'open' : ''}`}>
        <div
          className="dropdown-header"
          onClick={() => setIsOpen(!isOpen)}
          role="presentation"
        >
          <div className="left-section">
            {icon}
            <span>
              {placeholder}
            </span>
          </div>
          <span className={`dropdown-arrow `}>
            {isOpen ? <ArrowIcon /> : <DownArrowIcon />}
          </span>
        </div>
        {isOpen &&
          <div className={`dropdown-content ${isOpen ? 'open' : ''}`} ref={reference}>
            {isOpen && (
              <div className={isOpen ? 'dropdown-list' : 'opened'}>
                {options.map((option) => (
                  <span
                    key={option.value}
                    className='slide-left'
                  >
                    <input
                      type="checkbox"
                      checked={selectedValues.includes(option.value)}
                      onChange={() => handleCheckboxChange(option.value)}
                    />
                    <p>{option.label}</p>
                  </span>
                ))}
              </div>
            )}
          </div>
        }
      </div>
    </div>
  );
};
MultiSelectDropdown.defaultProps = {
  inputLabel: '',
  placeholder: '',
  icon: null,
};

export default MultiSelectDropdown;
