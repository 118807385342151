import React, { useState } from 'react';

import { ReactComponent as PersonIcon } from '../../assets/icons/person-icon.svg';
import { ReactComponent as UserIcon } from '../../assets/icons/user-icon.svg';
import { ReactComponent as UserTag } from '../../assets/icons/user-tag.svg';
import DefaultApiParameters from '../../enum/DefaultApiParameters';
import { useAppDispatch } from '../../redux/hooks';
import { fetchAllRoles } from '../../redux/slices/RolesManagementSlice';
import { fetchAllUsers } from '../../redux/slices/UsersManagementSlice';
import MyButton from '../../shared-components/button/Button';
import ModalBox from '../../shared-components/modal-box/ModalBox';
import SuccessNotification from '../../shared-components/success-notification/SuccessNotificaion';
import TabMenus from '../../shared-components/tab-menus/TabMenus';

import AddRole from './components/add-role';
import AddUser from './components/add-user';
import RolesList from './components/roles-list';
import UsersList from './components/users-list';

/**
 * User Management page function component
 */
function UserManagement() {
  const dispatch = useAppDispatch();
  const [openStatus, setOpenStatus] = useState(false);
  const [openTab, setOpenTab] = useState(0);
  const [modalView, setModalView] = useState({
    title: 'Add Account',
    header: true,
    content: <div />,
    rightCorner: '',
  });

  /**
   * Modal box close function
   */
  const closeModal = () => {
    setOpenStatus(false);
  };

  /**
   * Completed the add and update task
   * @param message 
   */
  const completedTask = (message: string, type: string) => {
    if (type === 'role') {
      dispatch(fetchAllRoles({
        page: DefaultApiParameters.page,
        limit: DefaultApiParameters.limit,
      }));
    }
    if (type === 'user') {
      dispatch(fetchAllUsers({
        page: DefaultApiParameters.page,
        limit: DefaultApiParameters.limit,
      }));
    }
    setOpenStatus(true);
    setModalView({
      title: '',
      header: false,
      content: (
        <SuccessNotification
          title={message}
          description=""
          notificationCloseTimer={3000}
          onNotificationClose={closeModal}
        />
      ),
      rightCorner: '',
    });
  };

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <ModalBox
        open={openStatus}
        title={modalView.title}
        content={modalView.content}
        closeModal={() => setOpenStatus(false)}
        header={modalView.header}
        rightCorner={modalView.rightCorner}
      />
      <div className="container">
        <div className="header-title">
          <h2>User & Role Management</h2>
        </div>
        <div className="body-container">
          <div className="card-table-tab">
            <TabMenus
              openTab={openTab}
              setOpenTab={setOpenTab}
              menus={[
                {
                  name: 'User',
                  icon: <PersonIcon />,
                },
                {
                  name: 'Role',
                  icon: <UserTag />,
                },
              ]}
            />
            <div>
              <MyButton
                label={openTab === 0 ? 'Add User' : 'Add Role'}
                buttonType="back"
                onClickFunc={() => {
                  setOpenStatus(true);
                  setModalView({
                    title: openTab === 0 ? 'Add User' : 'Add Role',
                    header: true,
                    content:
                      openTab === 0 ? (
                        <AddUser closeModal={closeModal} completedTask={completedTask} />
                      ) : (
                        <AddRole closeModal={closeModal} completedTask={completedTask} />
                      ),
                    rightCorner: '',
                  });
                }}
                svgIcon={openTab === 0 ? <UserIcon /> : <UserTag />}
              />
            </div>
          </div>
          <div className="table-content">
            {openTab === 0 && <UsersList />}
            {openTab === 1 && <RolesList />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserManagement;
