import React, { useEffect, useState, useRef } from 'react';

import { ReactComponent as Brightness } from '../../assets/icons/brightness.svg';
import { ReactComponent as Darkness } from '../../assets/icons/darkness.svg';
import { ReactComponent as Desktop } from '../../assets/icons/desktop.svg';
import { ReactComponent as Downarrow } from '../../assets/icons/down-arrow.svg';
import { ReactComponent as Notification } from '../../assets/icons/notification.svg';
import logo from '../../assets/images/logo.png';
import profile from '../../assets/images/profiles/profile.jpg';

/**
 * Header function component
 */
function Header() {
  const reference = useRef<HTMLDivElement>(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownNotification, setDropdownNotification] = useState(false);

  /**
   * My profile function
   */
  const myProfile = () => setDropdownOpen(false);
  /**
   * Drop down function
   */
  const dropdown = () => setDropdownOpen(true);

  /**
   * Logout function
   */
  const logout = () => setDropdownOpen(false);

  /**
   * Light and Darkness function
   */
  const lightDarkness = (data: string) => {
    console.log(data);
    setDropdownOpen(false);
  };

  const headerList1 = [
    {
      icon: Brightness,
      active: true,
      key: 'light',
      function: lightDarkness,
    },
    {
      icon: Darkness,
      active: false,
      key: 'dark',
      function: lightDarkness,
    },
    {
      icon: Desktop,
      active: false,
      key: 'desk',
      function: lightDarkness,
    },
  ];
  const headerList2 = [
    {
      label: 'My Profile',
      addClass: '',
      function: myProfile,
    },
    {
      label: 'Logout',
      addClass: 'text-danger',
      function: logout,
    },
  ];

  /**
   * Handle the close th drop down function
   * @param event 
   */
  const handleClickOutside = (event: MouseEvent) => {
    if (reference.current && !reference.current.contains(event.target as Node)) {
      setDropdownOpen(false);
      setDropdownNotification(false);
    }
  };
  useEffect((): void => {
    document.addEventListener('mousedown', handleClickOutside);
  }, []);
  return (
    <div className="admin-header-warp">
      <div className="admin-header-content">
        <div className="admin-header-left"> </div>
        <div className="admin-header-center">
          <div className="header-image">
            <img src={logo} alt="logo" />
          </div>
        </div>
        <div className="admin-header-right">
          <div className="item">
            <div className="notification" onClick={() => setDropdownNotification(true)} role="presentation">
              <Notification />
              <div className="tag tag-danger">3</div>
            </div>
            {
              dropdownNotification && (
                <div className="dropdown-notification" ref={reference}>
                  <div className="dropdown-layout">
                    asfdas
                  </div>
                </div>
              )
            }
          </div>
          <div className="item">
            <div className="profile">
              <div className="item profile-image">
                <img src={profile} alt="profile" />
              </div>
              <div className="item profile-details" onClick={dropdown} role="presentation">
                <div className="profile-name">Karthick</div>
                <div className="profile-dropdown-icon">
                  <Downarrow />
                </div>
              </div>
              {
                dropdownOpen && (
                  <div className="dropdown-content" ref={reference}>
                    <div className="dropdown-layout">
                      <div className="dropdown-header">
                        {
                          headerList1.map((value: any) => (
                            <div className={`header-item ${value.active && 'open'}`} onClick={() => value.function(value.key)} role="presentation"><value.icon /></div>
                          ))
                        }
                      </div>
                      <div className="dropdown-body">
                        <div className="body-list">
                          {
                            headerList2.map((value) => (
                              <div className="item" onClick={value.function} role="presentation">
                                <div className={value.addClass}>{value.label}</div>
                                <div className="list-icon"><Downarrow /></div>
                              </div>
                            ))
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
