import React, { useEffect, useState } from 'react';

import { ReactComponent as EditPencil } from '../../../assets/icons/edit-pencil.svg';
import { ReactComponent as Export } from '../../../assets/icons/export.svg';
import { ReactComponent as MultiUsers } from '../../../assets/icons/multi-users.svg';
import DefaultApiParameters from '../../../enum/DefaultApiParameters';
import { ApiRequest } from '../../../interface/common.interface';
import { RoleInterFace } from '../../../interface/role-management.interface';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { fetchAllRoles } from '../../../redux/slices/RolesManagementSlice';
import MyButton from '../../../shared-components/button/Button';
import CustomTable from '../../../shared-components/custom-table/CustomTable';
import ModalBox from '../../../shared-components/modal-box/ModalBox';
import SuccessNotification from '../../../shared-components/success-notification/SuccessNotificaion';

import AddRole from './add-role';
import FilterRole from './filter-role';

interface ModalState {
  status: boolean;
  title: string;
  header: boolean;
  content: JSX.Element;
  rightCorner: string;
}

/**
 * User Management page function component
 */
function RolesList() {
  const dispatch = useAppDispatch();
  const { roles, limit, page, totalPages, totalResults, loading } = useAppSelector((state) => state.roles);
  const column = [
    {
      label: 'Date',
      accessorKey: 'date' as keyof RoleInterFace,
    },
    {
      label: 'Role Name',
      accessorKey: 'name' as keyof RoleInterFace,
    },
    {
      label: 'Responsibility',
      accessorKey: 'responsibility' as keyof RoleInterFace,
    },
  ];
  const [modalView, setModalView] = useState<ModalState>({
    status: false,
    title: '',
    header: true,
    content: <div />,
    rightCorner: '',
  });

  /**
   * Fetch Users list function
   */
  const fetchData = (data: ApiRequest) => {
    if (!loading) {
      dispatch(fetchAllRoles(data));
    }
  };
  /**
   * Modal box close function
   */
  const closeModal = () => {
    setModalView({
      status: false,
      title: '',
      header: true,
      content: <div />,
      rightCorner: '',
    });
  };
  
  /**
   * Completed the update task
   * @param message 
   */
  const completedTask = (message: string) => {
    fetchData({
      limit: DefaultApiParameters.limit,
      page: DefaultApiParameters.page,
    });
    setModalView({
      status: true,
      title: '',
      header: false,
      content: (
        <SuccessNotification
          title={message}
          description=""
          notificationCloseTimer={3000}
          onNotificationClose={closeModal}
        />
      ),
      rightCorner: '',
    });
  };

  /**
   * User details view function
   */
  const viewFunction = (currentData: String) => {
    const roleDetail: RoleInterFace | undefined = roles.find(
      (value: RoleInterFace) => value.id === currentData,
    );
    setModalView({
      status: true,
      title: 'Edit Role',
      header: true,
      content: <AddRole closeModal={closeModal} roleDetail={roleDetail} completedTask={completedTask} />,
      rightCorner: '',
    });
  };

  /**
   * User filter function
   */
  const filterFunction = () => {
    setModalView({
      status: true,
      title: 'Roles Filter',
      header: false,
      content: <FilterRole closeModal={closeModal} />,
      rightCorner: 'filter-modal',
    });
  };

  /**
   * Table pagination function
   * @param value
   */
  const pageNateLimitChange = (value: ApiRequest) => {
    fetchData(value);
  };

  useEffect(() => {
    if (roles.length === 0) {
      fetchData({
        limit,
        page,
      });
    }
  }, []);

  return (
    <div className="table-content">
      <ModalBox
        open={modalView.status}
        title={modalView.title}
        content={modalView.content}
        closeModal={closeModal}
        header={modalView.header}
        rightCorner={modalView.rightCorner}
      />
      <CustomTable
        header={column}
        data={roles}
        serialNo
        actionButton
        height={432}
        loading={loading}
        page={page}
        limit={limit}
        totalPages={totalPages}
        totalResults={totalResults}
        pageLimitChange={pageNateLimitChange}
        filterClearFunction={() => {}}
        filterFunction={filterFunction}
        actionButtonList={[
          {
            icon: <EditPencil />,
            function: viewFunction,
            color: 'view-color',
          },
        ]}
        footerLeft={
          <div className="table-bottom-button-list">
            <div className="table-button-item">
              <MyButton
                label="Export Data"
                buttonType="back"
                onClickFunc={() => {}}
                svgIcon={<Export />}
              />
            </div>
            <div className="table-button-item">
              <div className="user-count">
                <div className="icon">
                  <MultiUsers />
                </div>
                <div className="value">{totalResults} Users</div>
              </div>
            </div>
          </div>
        }
      />
    </div>
  );
}

export default RolesList;
