import React, { useState } from 'react';

import { ReactComponent as Search } from '../../../assets/icons/search.svg';
import { FilterChargingStationInterFace } from '../../../interface/charging-station.interface';
import MyButton from '../../../shared-components/button/Button';
import CustomInput from '../../../shared-components/custom-input/CustomInput';
// import roleApi from '../../../utils/api/roles';
import SelectFiled from '../../../shared-components/select-field/SelectField';

interface FilterChargingInterface {
  closeModal: () => void;
}
/**
 * Role Add page function component
 */
function FilterChargingStation({ closeModal }: FilterChargingInterface) {
  const [filterDetails, setFilterDetails] = useState<FilterChargingStationInterFace>({
    search: '',
    phase: '',
    type: '',
    status: '',
  });

  /**
   * Handle the input change function
   * @param name
   * @param value
   */
  const handleInputChange = (name: string, value: string | number | (string | number)[]) => {
    setFilterDetails((previous) => ({
      ...previous,
      [name]: value,
    }));
  };

  /**
   * Handle the submit function
   */
  const handleSubmit = () => {
    const request = {
      search: filterDetails.search,
      phase: filterDetails.phase,
      type: filterDetails.type,
      status: filterDetails.status,
    };
    console.log(request);
  };

  return (
    <div className="filter-user-warp">
      <div className="form-body">
        <div className="form-field">
          <CustomInput
            name="search"
            inputLabel="Station Name / Location"
            type="text"
            placeholder="Search here..."
            value={filterDetails.search}
            onChangeValue={handleInputChange}
            svgIcon={<Search />}
          />
        </div>
        <div className="form-field">
          <SelectFiled
            name="status"
            inputLabel="Status"
            onChangeValue={handleInputChange}
            options={[
              {
                label: 'All',
                value: 'all',
              },
              {
                label: 'Available',
                value: 'available',
              },
              {
                label: 'Unavailable',
                value: 'unavailable',
              },
              {
                label: 'Active',
                value: 'active',
              },
              {
                label: 'Inactive',
                value: 'inactive',
              },
              {
                label: 'Maintenance',
                value: 'maintenance',
              },
            ]}
            selectedValue={filterDetails.status}
          />
        </div>
        <div className="form-field">
          <SelectFiled
            name="phase"
            inputLabel="Phase"
            onChangeValue={handleInputChange}
            placeholder="Select Phase"
            options={[
              {
                label: 'All',
                value: 'all',
              },
            ]}
            selectedValue={filterDetails.phase}
          />
        </div>
        <div className="form-field">
          <SelectFiled
            name="type"
            inputLabel="Charging Type"
            onChangeValue={handleInputChange}
            placeholder="Select Charging Type"
            options={[
              {
                label: 'All',
                value: 'all',
              },
            ]}
            selectedValue={filterDetails.type}
          />
        </div>
        <div className="form-field table-bottom-button-list form-bottom-button end">
          <div className="table-button-item">
            <MyButton label="Cancel" buttonType="back" onClickFunc={closeModal} />
          </div>
          <div className="table-button-item">
            <MyButton label="Submit" buttonType="submit" onClickFunc={handleSubmit} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default FilterChargingStation;
