import React, { useState } from 'react';

import { FilterRoleInterFace } from '../../../interface/role-management.interface';
import MyButton from '../../../shared-components/button/Button';
import CustomDateRange from '../../../shared-components/custom-date-range/CustomDateRange';
import CustomInput from '../../../shared-components/custom-input/CustomInput';
// import roleApi from '../../../utils/api/roles';

interface FilterRoleInterface {
  closeModal: () => void;
}
/**
 * Role Filter function component
 */
function FilterRole({ closeModal }: FilterRoleInterface) {
  const [filterDetails, setFilterDetails] = useState<FilterRoleInterFace>({
    search: '',
    fromDate: '',
    toDate: '',
  });

  /**
   * Handle the input change function
   * @param name
   * @param value
   */
  const handleInputChange = (name: string, value: string | number | (string | number)[]) => {
    setFilterDetails((previous) => ({
      ...previous,
      [name]: value,
    }));
  };

  /**
   * Handle the submit function
   */
  const handleSubmit = () => {
    const request = {
      search: filterDetails.search,
      fromDate: filterDetails.fromDate,
      toDate: filterDetails.toDate,
    };
    console.log(request);
  };

  return (
    <div className="filter-user-warp">
      <div className="form-body">
        <div className="form-field">
          <CustomInput
            name="search"
            inputLabel="User Name"
            type="text"
            placeholder="Enter the name"
            value={filterDetails.search}
            onChangeValue={handleInputChange}
          />
        </div>
        <div className="form-field">
          <CustomDateRange
            name1="fromDate"
            name2="toDate"
            inputLabel="Date"
            valueRequired
            value1={filterDetails.fromDate}
            value2={filterDetails.toDate}
            onChangeValue={handleInputChange}
          />
        </div>
        <div className="form-field table-bottom-button-list form-bottom-button end">
          <div className="table-button-item">
            <MyButton label="Cancel" buttonType="back" onClickFunc={closeModal} />
          </div>
          <div className="table-button-item">
            <MyButton label="Submit" buttonType="submit" onClickFunc={handleSubmit} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default FilterRole;
