interface MyButtonProperties {
  label: string;
  disableHoverEffect?: boolean;
  buttonType: 'submit' | 'back' | 'error' | 'primary-outline' | 'danger-outline';
  svgIcon?: JSX.Element;
  svgBackIcon?: JSX.Element;
  onClickFunc: () => void;
  disabled?: boolean;
}

/**
 * My Button function component
 */
function MyButton({ label, buttonType, svgIcon, onClickFunc, disableHoverEffect, svgBackIcon, disabled }: MyButtonProperties) {
  return (
    <button type="button" className={`${buttonType}-button ${disableHoverEffect ? '' : 'on-hover'}`} onClick={onClickFunc} disabled={disabled}>
      {svgIcon && <span>{svgIcon}</span>}
      {label && <span>{label}</span>}
      {svgBackIcon && <span>{svgBackIcon}</span>}
    </button>
  );
}

MyButton.defaultProps = {
  svgIcon: null,
  svgBackIcon: null,
  disabled: false,
  disableHoverEffect: false,
};

export default MyButton;
