import React, { useState } from 'react';

import { ChargingStationInterFace } from '../../interface/charging-station.interface';
import ModalBox from '../../shared-components/modal-box/ModalBox';
import TabMenus from '../../shared-components/tab-menus/TabMenus';

import ApprovedList from './components/approved-list';
import RejectedList from './components/rejected-list';
import UnApprovedList from './components/unapproved-list';
import ViewChargingStation from './components/view-charging-station';

/**
 * Charging Station Management function component
 */
function ChargingStationManagement() {
  const [openStatus, setOpenStatus] = useState<boolean>(false);
  const [openTab, setOpenTab] = useState<number>(0);
  const [modalView, setModalView] = useState({
    title: 'Add Account',
    header: true,
    content: <>vds</>,
    rightCorner: '',
  })
  /**
    * Close modal box function
  */
  const closeModal = () => {
    setOpenStatus(false);
  }

  /**
    * View charging station modal box function
  */
  const viewFunction = (currentData: ChargingStationInterFace) => {
    setOpenStatus(true);
    setModalView({
      title: 'Add Account',
      header: false,
      content: (
        <ViewChargingStation details={currentData} closeModal={closeModal} />
      ),
      rightCorner: 'right-top',
    });
  }

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <ModalBox
        open={openStatus}
        title={modalView.title}
        content={modalView.content}
        closeModal={closeModal}
        header={modalView.header}
        rightCorner={modalView.rightCorner}
      />
      <div className="container">
        <div className="header-title">
          <h2>Charging Station Management</h2>
        </div>
        <div className="body-container">
          <div className="table-tab">
            <TabMenus openTab={openTab} setOpenTab={setOpenTab} menus={[
              {
                name: 'Approved',
              },
              {
                name: 'Unapproved',
                count: 3,
              },
              {
                name: 'Rejected',
              },
            ]} />
          </div>
          <div className="table-content">
            {
              openTab === 0 && (
                <ApprovedList viewFunction={viewFunction} />
              )
            }
            {
              openTab === 1 && (
                <UnApprovedList viewFunction={viewFunction} />
              )
            }
            {
              openTab === 2 && (
                <RejectedList viewFunction={viewFunction} />
              )
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChargingStationManagement;
