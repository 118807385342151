import React, { useState } from 'react';

import { ReactComponent as CaffeeIcon } from '../../../assets/icons/caffee-color-icon.svg';
import { ReactComponent as CarWashIcon } from '../../../assets/icons/car-wash-color-icon.svg';
import { ReactComponent as CheckCircleIcon } from '../../../assets/icons/check-circle.svg';
import { ReactComponent as CloseCircleIcon } from '../../../assets/icons/close-circle.svg';
import { ReactComponent as DocumentFile } from '../../../assets/icons/document-file.svg';
import { ReactComponent as EyeIcon } from '../../../assets/icons/eye-icon.svg';
import { ReactComponent as GasItemIcon } from '../../../assets/icons/gas-item-color-icon.svg';
import { ReactComponent as RelaxingIcon } from '../../../assets/icons/relasing-color-icon.svg';
import { ReactComponent as RestroomIcon } from '../../../assets/icons/restroom-color-icon.svg';
import { ReactComponent as SettingIcon } from '../../../assets/icons/setting-color-icon.svg';
import { ReactComponent as ShopIcon } from '../../../assets/icons/shopping-color-icon.svg';
import { ReactComponent as WifiIcon } from '../../../assets/icons/wifi-color-icon.svg';
import DefaultApiParameters from '../../../enum/DefaultApiParameters';
import { ChargingStationInterFace } from '../../../interface/charging-station.interface';
import { useAppDispatch } from '../../../redux/hooks';
import { fetchAllStation } from '../../../redux/slices/ApprovedStationManagementSlice';
import { fetchAllUnapprovedStation } from '../../../redux/slices/UnapprovedStationManagementSlice';
import MyButton from '../../../shared-components/button/Button';
import CustomInput from '../../../shared-components/custom-input/CustomInput';
import ImageViewer from '../../../shared-components/image-viewer/ImageViewer';
import ModalBox from '../../../shared-components/modal-box/ModalBox';
import SuccessNotification from '../../../shared-components/success-notification/SuccessNotificaion';
import stationApi from '../../../utils/api/charging-station';

import RejectStation from './reject-station';

/**
 * View charging station details function component
 */
function ViewChargingStation({
  details,
  closeModal,
}: {
  details: ChargingStationInterFace;
  closeModal: () => void;
}) {
  const dispatch = useAppDispatch();
  const [modalView, setModalView] = useState({
    content: <div />,
    status: false,
  });

  /**
   * Close the confirm modal box function
   */
  const closeConfirmModal = () => {
    setModalView({
      content: <div />,
      status: false,
    });
  };

  /**
   * Station the reject use reason
   * @param data
   */
  const stationReject = (data: ChargingStationInterFace) => {
    stationApi
      .statusChangeChargingStation(data.id, {
        submissionStatus: 'rejected',
        reason: data.reason,
      })
      .then(() => {
        closeConfirmModal();
        closeModal();
        dispatch(
          fetchAllUnapprovedStation({
            page: DefaultApiParameters.page,
            limit: DefaultApiParameters.limit,
            submissionStatus: 'pending',
          }),
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /**
   * Reject the station function
   */
  const rejectStation = () => {
    setModalView({
      content: (
        <RejectStation
          conformSubmit={stationReject}
          closeModal={closeConfirmModal}
          stationDetails={details}
        />
      ),
      status: true,
    });
  };

  /**
   * Approved the station function
   */
  const approvedStatus = () => {
    stationApi
      .statusChangeChargingStation(details.id, {
        submissionStatus: 'approved',
      })
      .then(() => {
        setModalView({
          content: (
            <SuccessNotification
              title="Station Approved Successfully..."
              description=""
              notificationCloseTimer={3000}
              onNotificationClose={() => {
                closeConfirmModal();
                closeModal();
                dispatch(
                  fetchAllUnapprovedStation({
                    page: DefaultApiParameters.page,
                    limit: DefaultApiParameters.limit,
                    submissionStatus: 'pending',
                  }),
                );
                dispatch(
                  fetchAllStation({
                    page: DefaultApiParameters.page,
                    limit: DefaultApiParameters.limit,
                    submissionStatus: 'approved',
                  }),
                );
              }}
            />
          ),
          status: true,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div className="view-charging-station-warp">
      <ModalBox
        title=""
        content={modalView.content}
        closeModal={closeConfirmModal}
        open={modalView.status}
        header={false}
        rightCorner=""
      />
      <div className="facility-details">
        <div className="form-field">
          <CustomInput
            name="name"
            inputLabel="Facility Name"
            type="text"
            readOnly
            placeholder="Enter Facility Name"
            value={details.name}
          />
        </div>
        <div className="form-field">
          <CustomInput
            name="document"
            inputLabel="Land Ownership Document"
            type="text"
            readOnly
            placeholder="Select Lond Ownership Document"
            value="Docum..."
            svgIcon={<EyeIcon />}
            frontSvgIcon={<DocumentFile />}
          />
        </div>
        <div className="form-field">
          <CustomInput
            name="report"
            inputLabel="EB Report Of Past 6 Months"
            type="text"
            readOnly
            placeholder="Select EB Report Of Past 6 Months"
            value="Docum..."
            svgIcon={<EyeIcon />}
            frontSvgIcon={<DocumentFile />}
          />
        </div>
        <div className="form-field">
          <CustomInput
            name="electricity"
            inputLabel="Electricity Contact Type"
            type="text"
            readOnly
            placeholder="Enter Electricity Contact Type"
            value={details.electricity}
          />
        </div>
        <div className="form-field">
          <CustomInput
            name="time"
            inputLabel="Operating Hours"
            readOnly
            type="text"
            placeholder="Enter Operating Hours"
            value={details.time}
          />
        </div>
        <div className="form-field">
          <CustomInput
            name="address"
            inputLabel="Address"
            type="text"
            readOnly
            placeholder="Enter Address"
            value={details.address}
          />
        </div>
        <div className="form-field">
          <CustomInput
            name="lat_lang"
            inputLabel="Latitude & Longitude"
            type="text"
            readOnly
            placeholder="Mark Latitude & Longitude"
            value={details.lat_long}
          />
        </div>
        <div className="form-field">
          <ImageViewer inputLabel="Upload Media" />
        </div>
        <div className="form-amenities-icons">
          <div className="title-label">Amenities</div>
          <div className="icon-list">
            <div className="icon-item">
              <div className="icon">
                <GasItemIcon />
              </div>
              <div className="title">Gas Station</div>
            </div>
            <div className="icon-item">
              <div className="icon">
                <WifiIcon />
              </div>
              <div className="title">Free Wifi</div>
            </div>
            <div className="icon-item">
              <div className="icon">
                <RestroomIcon />
              </div>
              <div className="title">Restroom</div>
            </div>
            <div className="icon-item">
              <div className="icon">
                <CaffeeIcon />
              </div>
              <div className="title">Caffe</div>
            </div>
            <div className="icon-item">
              <div className="icon">
                <ShopIcon />
              </div>
              <div className="title">Shopping</div>
            </div>
            <div className="icon-item">
              <div className="icon">
                <RelaxingIcon />
              </div>
              <div className="title">Relaxing Area</div>
            </div>
            <div className="icon-item">
              <div className="icon">
                <SettingIcon />
              </div>
              <div className="title">Work Shop</div>
            </div>
            <div className="icon-item">
              <div className="icon">
                <CarWashIcon />
              </div>
              <div className="title">Car Wash</div>
            </div>
          </div>
        </div>
        {details.submission.status === 'pending' && (
          <div className="table-bottom-button-list form-bottom-button">
            <div className="table-button-item">
              <MyButton
                label="Reject"
                buttonType="danger-outline"
                onClickFunc={rejectStation}
                svgIcon={<CloseCircleIcon />}
              />
            </div>
            <div className="table-button-item">
              <MyButton
                label="Approved"
                buttonType="submit"
                onClickFunc={approvedStatus}
                svgIcon={<CheckCircleIcon />}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ViewChargingStation;
