/* eslint-disable no-param-reassign */
import { ActionReducerMapBuilder, createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import DefaultApiParameters from '../../enum/DefaultApiParameters';
import { APIUserResponseInterface, UserInterFace, UserResponseInterFace } from '../../interface/users-management.interface';
import { changeDateFormat } from '../../shared-functions/DateTime';
import userApi from '../../utils/api/users';

// Define the type of data expected
interface FetchParameters {
  search?: string;
  page?: number;
  limit?: number;
}

interface UserInitialProperties {
  users: UserInterFace[];
  limit: number,
  page: number,
  totalPages: number,
  totalResults: number,
  loading: boolean;
}

// a thunk to fetch users
export const fetchAllUsers = createAsyncThunk<APIUserResponseInterface, FetchParameters>(
  'user-management/fetchAllUsers',
  async (data: FetchParameters) => {
    const response = await userApi.fetchUsers(data);
    return {
      ...response.data,
      results: response.data.results.map((roleData: UserResponseInterFace) => ({
        ...roleData,
        date: changeDateFormat(roleData.createdAt),
        roleName: roleData.role.name,
        role: roleData.role._id,
        id: roleData._id,
      })),
    };
  },
);

// Initial state
const initialState: UserInitialProperties = {
  users: [],
  limit: DefaultApiParameters.limit,
  page: DefaultApiParameters.page,
  totalPages: 0,
  totalResults: 0,
  loading: false,
};

// Create the slice
export const counterSlice = createSlice({
  name: 'users-management',
  initialState,
  reducers: {},
  /**
   * Use the extra reducers function
   * @param builder
   */
  extraReducers: (builder: ActionReducerMapBuilder<UserInitialProperties>) => {
    builder
      .addCase(fetchAllUsers.pending, (state: UserInitialProperties) => {
        state.loading = true;
      })
      .addCase(fetchAllUsers.fulfilled, (state: UserInitialProperties, action: PayloadAction<APIUserResponseInterface>) => {
        state.loading = false;
        state.users = [...action.payload.results];
        state.limit = action.payload.limit;
        state.page = action.payload.page;
        state.totalPages = action.payload.totalPages;
        state.totalResults = action.payload.totalResults;
      })
      .addCase(fetchAllUsers.rejected, (state: UserInitialProperties) => {
        state.loading = false;
        // Handle error if needed
      });
  },
});
// Export the reducer
export default counterSlice.reducer;
