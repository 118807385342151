import { UserParameters } from '../../interface/users-management.interface';

import deleteRequest from './base/delete';
import getRequest from './base/get';
import patchRequest from './base/patch';
import postRequest from './base/post';

interface QueryParameters {
  limit?: number;
  [key: string]: any;
}

export default {
  /**
   * Fetch the Accounts list with pagination
   */
  fetchUsers: (query: QueryParameters = {}): Promise<any> => {
    const parameters = { ...query };
    return getRequest(`${process.env.REACT_APP_API_URL}api/super-admin/v1/users`, {
      ...parameters,
    });
  },
  /**
   * Create the user api call function
   * @param data
   * @returns
   */
  createUser: (
    data: UserParameters = {
      name: '',
      email: '',
      role: '',
      password: '',
    },
  ): Promise<any> => {
    const parameters = { ...data };
    return postRequest(`${process.env.REACT_APP_API_URL}api/super-admin/v1/users`, {
      ...parameters,
    });
  },
  /**
   * Update the user api call function
   * @param data
   * @returns
   */
  editUser: (
    id: string,
    data: UserParameters = {
      name: '',
      email: '',
      role: '',
    },
  ): Promise<any> => {
    const parameters = { ...data };
    return patchRequest(`${process.env.REACT_APP_API_URL}api/super-admin/v1/users/${id}`, {
      ...parameters,
    });
  },
  /**
   * Delete the user api call function
   * @param data
   * @returns
   */
  deleteUser: (
    id: String,
  ): Promise<any> => {
    return deleteRequest(`${process.env.REACT_APP_API_URL}api/super-admin/v1/users/${id}`);
  },
};
