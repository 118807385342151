import postRequest from './base/post';

export default {
  /**
   * Super admin auth login function
   * @param data
   * @param onUploadProgress Optional callback for tracking upload progress
   */
  login: (data: any = {}): Promise<any> => {
    return postRequest(`${process.env.REACT_APP_AUTH_API_URL}api/super-admin/v1/auth/login`, data);
  },
};
