import { Navigate, Outlet, Route, Routes } from 'react-router-dom';

import Header from '../../shared-components/header/Header';
import NotificationBox from '../../shared-components/notifiaction-box/NotificationBox';
import Sidebar from '../../shared-components/sidebar/Sidebar';
import AccountManagement from '../account-management';
import ChargingStationManagement from '../charging-station';
import Dashboard from '../dashboard';
import EndConsumerManagement from '../end-consumer-management';
import Login from '../login';
import PaymentManagement from '../payments';
import ReportManagement from '../reports';
import UserManagement from '../user-management';

/**
 * Wrapper Route function component
 */
function WrapperRoute() {
  return (
    <div className="ev-layout-wrap">
      <div className="ev-sidebar">
        <Sidebar />
      </div>
      <div className="ev-content-wrap">
        <Header />
        <div className="ev-content">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

/**
 * Router page function component
 */
function Router() {
  const routes = [
    {
      path: 'dashboard',
      children: [
        {
          id: 1,
          path: '',
          element: <Dashboard />,
        },
      ],
    },
    {
      path: 'account',
      children: [
        {
          id: 2,
          path: '',
          element: <AccountManagement />,
        },
      ],
    },
    {
      path: 'charging-station',
      children: [
        {
          id: 3,
          path: '',
          element: <ChargingStationManagement />,
        },
      ],
    },
    {
      path: 'reports',
      children: [
        {
          id: 4,
          path: '',
          element: <ReportManagement />,
        },
      ],
    },
    {
      path: 'user',
      children: [
        {
          id: 5,
          path: '',
          element: <UserManagement />,
        },
      ],
    },
    {
      path: 'end-consumer',
      children: [
        {
          id: 6,
          path: '',
          element: <EndConsumerManagement />,
        },
      ],
    },
    {
      path: 'payments',
      children: [
        {
          id: 7,
          path: '',
          element: <PaymentManagement />,
        },
      ],
    },
  ];
  return (
    <>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="/ev-admin" element={<WrapperRoute />}>
          {routes.map((route) => (
            <Route path={route.path}>
              {route.children.map((subRoute) => (
                <Route
                key={subRoute?.id}
                path={subRoute?.path}
                element={subRoute?.element}
                />
              ))}
            </Route>
          ))}
        </Route>
      </Routes>
      <NotificationBox />
    </>
  );
}

export default Router;
