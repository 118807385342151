import React, { useState } from 'react';

import { ReactComponent as CheckCircle } from '../../../assets/icons/check-circle.svg';
import { ReactComponent as DocumentFile } from '../../../assets/icons/document-file.svg';
import { ReactComponent as DotCircle } from '../../../assets/icons/dot-circle.svg';
import { ReactComponent as EyeIcon } from '../../../assets/icons/eye-icon.svg';
import { ReactComponent as FacilityIcon } from '../../../assets/icons/facility-icon.svg';
import { ReactComponent as SARCurrency } from '../../../assets/icons/sar-currency.svg';
import { ReactComponent as WalletIcon } from '../../../assets/icons/wallet-icon.svg';
import logo from '../../../assets/images/login/logo.png';
import profile from '../../../assets/images/profiles/profile.jpg';
import { AccountsInterFace } from '../../../interface/account-management.interface';
import MyButton from '../../../shared-components/button/Button';
import TabMenus from '../../../shared-components/tab-menus/TabMenus';

/**
 * View Account function component
 */
function ViewAccount({ accountDetails = null, closeModal, conformSubmit }: { accountDetails?: AccountsInterFace | null; closeModal: () => void; conformSubmit: (value: AccountsInterFace | null) => void }) {
  const [openTab, setOpenTab] = useState(0);

  return (
    <div className="add-account-form">
      <div className="view-body">
        <div className="profile-details">
          <div className="profile-image">
            <img src={profile} alt="profile" />
          </div>
          <div className="profile-user">
            <div className="profile-title-1">{accountDetails?.name}</div>
            <div className="profile-title-2">{accountDetails?.email}</div>
          </div>
        </div>
        <TabMenus openTab={openTab} setOpenTab={setOpenTab} menus={[
          {
            name: 'Facility',
            icon: <FacilityIcon />,
          },
          {
            name: 'Wallet',
            icon: <WalletIcon />,
          },
        ]} />
        <div className="tab-body">
          <div className={`tab-contents ${openTab === 0 && 'active'}`}>
            <div className="tab-item">
              <div className="item-content">
                <div className="content-body">
                  {
                    accountDetails?.facility_details && accountDetails?.facility_details.map((facility, index) => (
                      <div className="list-item" key={index}>
                        <div className="total-details">
                          <div className="name">{facility.name}</div>
                          <div className="total">{facility.total}</div>
                        </div>
                        <div className="total-details">
                          <div className="name active"><DotCircle /> Active</div>
                          <div className="total center">{facility.active}</div>
                        </div>
                      </div>
                    ))
                  }
                </div>
                <div className="documents-session">
                  <div className="title">Government ID</div>
                  <div className="document-list">
                    {
                      accountDetails?.documents && accountDetails?.documents.map((document: string) => (
                        <div className="document-item">
                          <div className="icon"><DocumentFile /></div>
                          <div className="name">{document}</div>
                          <div className="icon"><EyeIcon /></div>
                        </div>
                      ))
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className="tab-item">
              <div className="item-content">
                <div className="content-body step-3">
                  <div className="top-logo">
                    <div className="logo-image">
                      <img src={logo} alt="logo" />
                    </div>
                  </div>
                  <div className="middle-price">
                    <div className="price-amount">
                      <SARCurrency /> {accountDetails?.total_price} ({accountDetails?.currency})
                    </div>
                  </div>
                  <div className="footer-detail">
                    <div className="title">{accountDetails?.name}</div>
                    <div className="title">{accountDetails?.accountCustomId}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div className="form-field table-bottom-button-list form-bottom-button">
          <div className="table-button-item">
            <MyButton
              label="Cancel"
              buttonType="back"
              onClickFunc={closeModal}
            />
          </div>
          <div className="table-button-item">
            <MyButton
              label="Verified"
              buttonType="submit"
              svgIcon={<CheckCircle />}
              onClickFunc={() => conformSubmit(accountDetails)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

ViewAccount.defaultProps = {
  accountDetails: {},
}

export default ViewAccount;
