import { ReactComponent as ArrowIcon } from '../../assets/icons/down-arrow.svg';

type Option = {
  value: string | number;
  label: string;
};

interface SelectFieldProperties {
  inputLabel?: string;
  options: Option[]; // Array of objects with value and label
  placeholder?: string;
  selectedValue: string | number;
  name: string;
  onChangeValue: (name: string, value: any) => void;
  valueRequired?: boolean;
}

/**
 * Select Box filed function component
 */
function SelectField({
  inputLabel,
  options,
  name,
  placeholder,
  selectedValue,
  onChangeValue,
  valueRequired,
}: SelectFieldProperties) {
  /**
   * Handle the value change function
   */
  const handleValueChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    onChangeValue(name, event?.target?.value);
  };

  return (
    <div className="custom-select-wrap">
      {inputLabel && (
        <p className="input-label">
          {inputLabel}
          {valueRequired && <span>*</span>}
        </p>
      )}
      <div className="custom-select-container">
        <select value={selectedValue} onChange={handleValueChange}>
          <optgroup>
            {placeholder && (
              <option value="" disabled selected>
                {placeholder}
              </option>
            )}
            {options?.map((option) => (
              <option key={option?.value} value={option?.value}>
                {option?.label}
              </option>
            ))}
          </optgroup>
        </select>
        <span className="custom-icon">
          <ArrowIcon />
        </span>
      </div>
    </div>
  );
}

SelectField.defaultProps = {
  inputLabel: '',
  placeholder: '',
  valueRequired: false,
};

export default SelectField;
