import { ReactComponent as DownArrow } from '../../assets/icons/down-arrow.svg';

type InputType = 'text' | 'number' | 'email' | 'password';
interface CustomInputProperties {
  inputLabel?: string;
  type: InputType;
  placeholder: string;
  value: string | number;
  name: string;
  onChangeValue?: (name: string, value: string | number) => void;
  valueRequired?: boolean;
  readOnly?: boolean;
}

/**
 * Custom Input box function component
 */
function CustomPhoneInput({
  inputLabel,
  type,
  name,
  placeholder,
  value,
  onChangeValue = () => {},
  valueRequired,
  readOnly,
}: CustomInputProperties) {
  /**
   * Input box change handle function
   */
  const handleValueChange = (changeValue: string | number) => {
    onChangeValue(name, changeValue);
  };

  return (
    <div className="custom-input-wrap">
      {inputLabel && (
        <p className="input-label">
          {inputLabel}
          {valueRequired && <span>*</span>}
        </p>
      )}
      <div className="custom-input-container">
        <div className="frond-text">+91 <DownArrow /></div>
        <input
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={(event) => handleValueChange(event.target.value)}
          required={valueRequired}
          readOnly={readOnly}
        />
      </div>
    </div>
  );
}

CustomPhoneInput.defaultProps = {
  inputLabel: '',
  valueRequired: false,
  readOnly: false,
  /**
   * Input change function
   */
  onChangeValue: () => {},
};

export default CustomPhoneInput;
