/* eslint-disable no-param-reassign */
import { ActionReducerMapBuilder, createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import DefaultApiParameters from '../../enum/DefaultApiParameters';
import { ChargingStationAPIResponse, ChargingStationInitialProperties, ChargingStationInterFace } from '../../interface/charging-station.interface';
import { changeDateFormat } from '../../shared-functions/DateTime';
import stationApi from '../../utils/api/charging-station';

// Define the type of data expected
interface FetchParameters {
  search?: string;
  page?: number;
  limit?: number;
  submissionStatus?: string;
}

// a thunk to fetch users
export const fetchAllUnapprovedStation = createAsyncThunk<ChargingStationAPIResponse, FetchParameters>(
  'unapproved-station-management/fetchAllUnapprovedStation',
  async (data: FetchParameters) => {
    const response = await stationApi.fetchChargingStations(data);
    return {
      ...response.data,
      results: response.data.results.map((stationData: ChargingStationInterFace) => ({
        ...stationData,
        date: changeDateFormat(stationData.createdAt),
        id: stationData._id,
      })),
    };
  },
);

// Initial state
const initialState: ChargingStationInitialProperties = {
  stations: [],
  limit: DefaultApiParameters.limit,
  page: DefaultApiParameters.page,
  totalPages: 0,
  totalResults: 0,
  loading: false,
};

// Create the slice
export const unapprovedStationSlice = createSlice({
  name: 'unapproved-station-management',
  initialState,
  reducers: {},
  /**
   * Use the extra reducers function
   * @param builder
   */
  extraReducers: (builder: ActionReducerMapBuilder<ChargingStationInitialProperties>) => {
    builder
      .addCase(fetchAllUnapprovedStation.pending, (state: ChargingStationInitialProperties) => {
        state.loading = true;
      })
      .addCase(
        fetchAllUnapprovedStation.fulfilled,
        (state: ChargingStationInitialProperties, action: PayloadAction<ChargingStationAPIResponse>) => {
          state.loading = false;
          state.stations = [...action.payload.results];
          state.limit = action.payload.limit;
          state.page = action.payload.page;
          state.totalPages = action.payload.totalPages;
          state.totalResults = action.payload.totalResults;
        },
      )
      .addCase(fetchAllUnapprovedStation.rejected, (state: ChargingStationInitialProperties) => {
        state.loading = false;
        // Handle error if needed
      });
  },
});
// Export the reducer
export default unapprovedStationSlice.reducer;
